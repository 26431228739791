/*Scrollbar css start from here*/

::-webkit-scrollbar {
  background-color: #fff;
  width: 8px;
  height: 8px;
}

/* ::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.2);
} */

/* ::-webkit-scrollbar-track:hover {
  background-color: rgba(0, 0, 0, 0.2);
} */

.colorRed {
  color: #c71616;
}

.textInput.blankValue {
  border: 1px solid #c71616;
}
.loanAmountTenurePopupCls {
  padding-top: 30px;
  padding-bottom: 10px;
}

.LoanTypeCard {
  border: 2px solid transparent;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 2px 5px 0 rgb(5 47 95 / 4%), 5px 5px 10px 0 rgb(52 105 203 / 10%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 195px;
  cursor: pointer;
  transition: all 0.3s;
  background-color: #fff;
}

.LoanTypeCard:hover {
  box-shadow: unset;
  border-color: #e0e0e0;
}

.LoanTypeCard.active,
.LoanTypeCard.active:hover {
  border-color: #4fbbc8;
  color: #4fbbc8;
}

.LoanTypeIcon {
  height: 80px;
  margin-bottom: 0.5rem;
}

.whenValue {
  width: 135px;
  position: absolute;
  top: 3px;
  left: 5px;
  padding: 5px;
  background: #fff;
  z-index: 2;
  color: #ddd;
}

.LoanTypeTitle {
  /* height: 40px;  */
  overflow: hidden;
}

.whenValue {
  width: 135px;
  position: absolute;
  top: 3px;
  left: 5px;
  padding: 5px;
  background: #fff;
  z-index: 2;
  color: #ddd;
}

.LoanTypeTitle {
  /* height: 40px;  */
  overflow: hidden;
}

.display_none {
  display: none !important;
}

.react-transform-wrapper {
  width: 100% !important;
}

.mypdf {
  width: 100%;
  height: 570px;
}

.mypdf .react-pdf__Page {
  width: 100%;
  height: 90%;
  overflow: hidden;
}

.mypdf .react-pdf__Page canvas {
  width: 100%;
  height: 100%;
}

.tabDefaultSec {
  border: 1px solid #4fbbc8;
  border-radius: 0.25rem;
  background-color: #fff;
  padding: 0.25rem 0.5rem;
  font-size: 0.8175rem;
  min-height: unset;
  line-height: unset;
  color: #4fbbc8;
  margin-right: 0.75rem;
  font-weight: normal;
}

.tabDefaultSec1 {
  border-bottom: 2px solid transparent;
  padding: 0.2rem 0;
  font-size: 0.8175rem;
  min-width: unset;
  min-height: unset;
  line-height: unset;
  margin-right: 1rem;
  font-weight: 500;
}

.tabDefaultSec.tabSelectedSec {
  border-color: #0d4689;
  color: #0d4689;
  font-weight: 600;
}

.tabDefaultSec.tabSelected {
  background-color: #4fbbc8;
  color: #fff;
}

.tabDefaultSec1.tabSelectedSec {
  border-color: #0d4689;
  color: #0d4689;
  font-weight: 600;
}

.tabDefaultSecc {
  border-bottom: 2px solid transparent;
  padding: 0.2rem 0;
  font-size: 0.8175rem;
  min-width: unset;
  min-height: unset;
  line-height: unset;
  margin-right: 1rem;
  font-weight: 500;
}

.rightBoxHeading {
  background-color: #4fbbc8;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  z-index: 999;
  color: #fff;
  border-radius: 0.25rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-weight: 500;
  text-align: center;
}

.bsPdf {
  position: absolute;
  bottom: 0.25rem;
  right: 0.25rem;
  color: #4fbbc8;
}

.graphKpi {
  border-radius: 0.5rem;
  padding: 0.4rem 0.75rem;
  position: relative;
  transition: all 0.2s ease-out;
  background-color: #dfd9e5;
  border-left: 4px solid #81748f;
}

.graphKpi1 {
  background-color: #dae8f6;
  border-left: 4px solid #5d758d;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 16px;
}

.colorBlue {
  color: #0d4689;
}

.paddingontd td {
  padding: 6px !important;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a5;
}

::-webkit-scrollbar-button {
  display: none;
}

/*Scrollbar css end*/

body .noPadding {
  padding: 0;
}

body a {
  font-weight: normal !important;
}

body .headerHolder {
  display: flex;
  background: #fff;
  justify-content: space-between;
  padding: 0 20px;
  height: 49px;
  border-bottom: 1px solid #f0f0f0;
  top: 0;
  left: 200px;
  right: 0;
  /* width: calc(100% - 200px); */
  transition: all 0.2s;
  z-index: 999;
}

body.sidebarOpen .headerHolder {
  left: 50px;
}

.headerHolderr {
  background: #fff;
  justify-content: space-between;
  padding: 0 20px;
  height: 48px;
}

.headerHolderSec {
  padding: 0 1.5rem;
}

.mainBg {
  background-color: rgb(239, 241, 245);
  min-height: 100vh;
}

.pageHeading {
  -webkit-box-shadow: 0 1px 2px rgb(56 65 74 / 15%);
  box-shadow: 0 1px 2px rgb(56 65 74 / 15%);
  padding: 10px 1.5rem;
  background-color: #fff;
  margin: -24px -1.5rem 1.5rem -1.5rem;
}

body .searchInput {
  padding: 4px 0;
}

body .squareIconButton {
  border-radius: 3px;
  border: 1px solid #4fbbc8;
  padding: 6px;
  color: #4fbbc8;
  background-color: #fff;
}

body .squareIconButton1 {
  border-radius: 3px;
  border: 1px solid #4fbbc8;
  padding: 6px;
  color: #4fbbc8;
  /* background-color: #fff; */
}

body .squareIconButtonRemove {
  border-radius: 3px;
  border: 1px solid #4fbbc8;
  padding: 6px;
  color: #4fbbc8;
  background-color: #fff;
}

.hideElementItem {
  display: none;
}

body .disabledBtn {
  border-radius: 3px;
  border: 1px solid #4fbbc8;
  padding: 6px;
  color: #4fbbc8 !important;
  background-color: #fff;
  opacity: 0.4;
}

body .squareIconButton:hover {
  border: 1px solid transparent;
  background-color: #4fbbc8;
  color: #fff;
}

.squareIconButtonn {
  border-radius: 4px;
  border: 1px solid #4fbbc8;
  padding: 5px;
  color: #fff;
  background-color: #4fbbc8;
}

.squareIconButtonn:hover {
  background-color: #fff;
  color: #4fbbc8;
}

.deleteIcon .squareIconButtonn {
  background-color: #ce0101;
  border-color: #ce0101;
}

.deleteIcon .squareIconButtonn:hover {
  background-color: #fff;
  color: #ce0101;
}

body .squareIconButtonRemove:hover {
  border: 1px solid #c71616;
  background: #c71616;
  color: #fff;
}

body .squareIconButtonFilled {
  border: 1px solid #4fbbc8;
  background-color: #4fbbc8;
  color: #fff;
}

body .squareIconButtonFilled:hover {
  border: 1px solid #4fbbc8;
  color: #4fbbc8;
  background-color: #fff;
}

body .fixedBox {
  height: 75vh;
  overflow-y: auto;
}

body .formLabel {
  margin-bottom: 8px;
  color: #718096;
  font-size: 0.8125rem;
  font-weight: 300;
}

.required {
  position: relative;
}

.required::after {
  content: '*';
  color: #ef3d46;
  font-size: 0.875rem;
}

body .formRowHolder {
  width: 60%;
  padding-right: 70px;
  margin-bottom: 20px;
  position: relative;
}

body .formRowHolder.fullrow {
  width: 100%;
  padding-right: 0;
}

body .textInput {
  padding: 8.5px 10px;
  background-color: #fff;
  border-radius: 8px;
}

body .textInput1 {
  padding: 8.5px 10px;
  background-color: #fff;
  border-radius: 8px;
  font-weight: 500;
}

body .textInput1::placeholder {
  font-weight: unset;
}

/* TabStyles.css */

/* .tabsContainer {
  display: flex;
  flex-wrap: wrap; 
  overflow: hidden; 
} */

/* .tabOuter {
  display: flex;
  flex-wrap: wrap; 
  width: 100%;
} */

/* .tabDefault {
  min-width: 150px; 
  flex: 0 0 auto; 
  text-align: center;
} */

/* .tabIndicator {
  width: 100%;
}

.tabSelected {
  background-color: #f0f0f0; 
} */

/* Ensuring the tab container wraps content */
/* .MuiTabs-root {
  width: 100%;
  display: flex;
  flex-wrap: wrap; 
  overflow: hidden;
} */

/* .MuiTabs-scroller {
  display: flex;
  flex-wrap: wrap; 
} */

/* .MuiTab-root {
  min-width: 150px; 
  flex: 0 0 auto;
  text-align: center;
} */

body .textInputTable {
  padding: 6px 9px;
}
.textInputEnabledWhenDisabled {
  color: #000000;
  font-weight: bold;
}

/* Override WebkitTextFillColor for disabled state */
.textInputEnabledWhenDisabled.Mui-disabled {
  color: #000000; /* Ensure the color is set */
  -webkit-text-fill-color: #000000; /* Override default disabled color */
  background-color: transparent; /* Optional: Override background color */
}
body .autoCompleteInput {
  background-color: #fff;
}
body .autoCompleteInputTable {
  padding: 4px 9px !important;
  background-color: #fff;
}
body .textInputTable1 {
  padding: 6px 9px;
  font-size: 12px;
}

body .autoCompleteTable {
  padding: 4px 9px !important;
  background-color: #fff;
}

body .headerHolderSec {
  padding: 0 1.5rem;
  background: #fff;
  display: flex;
  height: 48px;
  justify-content: space-between;
}

body .addMoreIcon {
  position: absolute;
  top: 30px;
  right: 30px;
}

.fileInput {
  display: none;
}

body .menuOpened .MuiMenu-paper {
  overflow: visible;
}

body .displayDateRange {
  position: absolute;
  bottom: 8px;
  left: 33px;
  font-size: 14px;
  z-index: 999;
}

body .actionButton {
  border: 1px solid transparent;
  color: #fff;
  background-color: #0d4689;
}

body .actionButton1 {
  border: 1px solid transparent;
  color: #fff;
  background-color: #4fbbc8;
}

body .disableButton {
  border: 1px solid transparent;
  color: #fff !important;
  background-color: #0d4689 !important;
  opacity: 0.4;
}

body .actionButton:hover {
  border-color: #0d4689;
  background-color: #fff;
  color: #0d4689;
}

.mb-1 {
  margin-bottom: 0.5rem;
}

.mb-2 {
  margin-bottom: 1rem;
}

.mb-3 {
  margin-bottom: 1.5rem;
}

.mb-4 {
  margin-bottom: 2rem;
}

.mb-5 {
  margin-bottom: 2.5rem;
}

.mb-6 {
  margin-bottom: 3rem;
}

.mb-7 {
  margin-bottom: 3.5rem;
}

.mt-1 {
  margin-top: 0.5rem;
}

.mt-2 {
  margin-top: 1rem;
}

.mt-3 {
  margin-top: 1.5rem;
}

.mt-4 {
  margin-top: 2rem;
}

.mr-1 {
  margin-right: 0.5rem;
}

.mr-2 {
  margin-right: 1rem;
}

.mr-3 {
  margin-right: 1.5rem;
}

.mr-4 {
  margin-right: 2rem;
}

.ml-1 {
  margin-left: 0.5rem;
}

.ml-2 {
  margin-left: 1rem;
}

.ml-3 {
  margin-left: 1.5rem;
}

.ml-4 {
  margin-left: 2rem;
}

.pb-05 {
  padding-bottom: 0.25rem;
}

.pb-1 {
  padding-bottom: 0.5rem;
}

.pb-2 {
  padding-bottom: 1rem;
}

.pb-3 {
  padding-bottom: 1.5rem;
}

.pb-4 {
  padding-bottom: 2rem;
}

.pt-05 {
  padding-top: 0.25rem;
}

.pt-1 {
  padding-top: 0.5rem;
}

.pt-2 {
  padding-top: 1rem;
}

.pt-3 {
  padding-top: 1.5rem;
}

.pt-4 {
  padding-top: 2rem;
}

.pr-05 {
  padding-right: 0.25rem;
}

.pr-1 {
  padding-right: 0.5rem;
}

.pr-2 {
  padding-right: 1rem;
}

.pr-3 {
  padding-right: 1.5rem;
}

.pr-4 {
  padding-right: 2rem;
}

.pl-05 {
  padding-left: 0.25rem;
}

.pl-1 {
  padding-left: 0.5rem;
}

.pl-2 {
  padding-left: 1rem;
}

.pl-3 {
  padding-left: 1.5rem;
}

.pl-4 {
  padding-left: 2rem;
}

/********* new style *********/

.greenBorder {
  border: 1px solid #4fbbc8;
}

.positionRel {
  position: relative;
}

.overflowVisible {
  overflow: visible;
}

.textAlignCenter {
  text-align: center;
}

.w-100 {
  width: 100%;
}

.w-50 {
  width: 50%;
}

.w-40 {
  width: 40%;
}

.w-30 {
  width: 30%;
}

.w-25 {
  width: 25%;
}

.displayFlex {
  display: flex;
}

.displayTable {
  display: table;
}

.displayTableRow {
  display: table-row;
}

.displayTableCell {
  display: table-cell;
}

.alignItemCenter {
  align-items: center;
}

.alignItemTop {
  align-items: flex-start;
}

.justifyContentBetween {
  justify-content: space-between;
}

.justifyContentCenter {
  justify-content: center;
}

.justifyContentRight {
  justify-content: right;
}

.flexWrap {
  flex-wrap: wrap;
}

.footerBox {
  padding: 8px 20px;
  text-align: center;
  border-top: 1px solid #eaedf1;
  position: fixed;
  width: 100%;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: #fff;
  z-index: 999;
}

.footerContent {
  padding-left: 200px;
}

.appSubmissionLinkB {
  border: 1px solid #eaedf1;
  border-radius: 4px;
  padding: 1.5rem 1rem;
  background-color: #eaedf1;
  /* color: #0d4689; */
  transition: all 0.3s;
  cursor: pointer;
}

.appSubmissionLinkB:hover {
  box-shadow: 0 0 20px -8px rgba(0, 0, 0, 0.2);
  color: #0d4689;
  background-color: #fff;
}

.appSubLinkIcon {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  /* color: #0d4689; */
}

.popupTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.popupTitlee {
  color: rgb(33, 43, 54);
}

.PopupContent {
  min-width: 320px;
  width: 540px;
}

.uploadBox {
  border-radius: 0.5rem;
  text-align: center;
  /* padding: 1rem;  */
  cursor: pointer;
  background-color: #fff;
  transition: all 0.3s;
  height: 100%;
  /* box-shadow: 0 0 20px -8px rgba(0, 0, 0, 0.2); */
  color: #4fbbc8;
  border: 1px solid #4fbbc8;
}

.uploadBoxSec {
  height: 125px;
}

.uploadBoxHight {
  height: 60vh;
}

.uploadBoxHight label {
  padding: 1rem;
  height: 100%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.uploadBoxSec label {
  padding: 1rem;
  height: 100%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.uploadBox:hover,
.uploadBox:focus {
  color: #0d4689;
  border-color: #0d4689;
}

.upladIcon {
  margin-bottom: 0.5rem;
  font-size: 2.5rem;
}

.uploadPreview {
  margin-bottom: 1.5rem;
}

.downloadBtn {
  padding-top: 1rem !important;
  padding-bottom: 1.5rem !important;
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.downloadIcon {
  margin-bottom: 0.5rem;
  font-size: 2.5rem;
}

.downloadBtnSec {
  height: 125px;
  padding: 0.75rem 2rem;
}

.cardDis {
  border-radius: 1rem;
  padding: 1rem 1rem;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  min-height: 100px;
  top: 0px;
  position: relative;
  transition: all 0.2s ease-out;
}

.cardDis1 {
  /* border:1px solid #eaedf1; */
  border-radius: 1rem;
  padding: 1rem 1rem;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  min-height: 100px;
}

.cardDisNoFlex {
  display: block;
}

.disIcon {
  font-size: 1.25rem;
  color: #0d4689;
}

/* .headingBig {color: #0d4689; font-weight: 500;} */

.disIconOuter {
  padding: 0.75rem;
  border-radius: 2rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
}

.cardColor1 {
  background-color: #e2e8f0;
}

.cardColor1 .disIconOuter {
  background-color: rgb(140 148 159 / 20%);
}

.cardColor2 {
  background-color: #dae8f6;
}

.cardColor2 .disIconOuter {
  background-color: rgb(84 124 162 / 20%);
}

.cardColor3 {
  background-color: #dfd9e5;
}

.cardColor3 .disIconOuter {
  background-color: rgb(160 128 192 / 20%);
}

.cardColor4 {
  background-color: #d9e8d8;
}

.cardColor4 .disIconOuter {
  background-color: rgb(91 134 88 / 20%);
}

.headingSmall {
  font-size: 0.8rem;
}

.veriftBtn {
  background-color: #27295f;
  color: #fff;
  padding: 0.5rem 1rem;
  margin-top: 1rem;
}

.veriftBtn:hover,
.veriftBtn:focus {
  background-color: #166bac;
}

.verifyCardB {
  border: 1px solid #166bac;
  border-radius: 8px;
  padding: 1rem;
  position: relative;
}

.verifyCardLine {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.veriftBtn {
  background-color: #27295f;
  color: #fff;
  padding: 0.5rem 1rem;
  margin-top: 1rem;
}

.veriftBtn:hover,
.veriftBtn:focus {
  background-color: #166bac;
}

.verifyIcon {
  color: green;
  font-size: 1rem;
}

.verifyHeading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.verifiedCardB {
  border-color: green;
}

.verifiedCardB .veriftBtn {
  background-color: green;
}

.reVerifyCardB {
  border-color: #ec3a46;
}

.reVerifyCardB .veriftBtn {
  background-color: #ec3a46;
}

.reVerifyIcon {
  color: #ec3a46;
  font-size: 1.25rem;
}

/*********** preview report start *********/

.viewLeadCard1 {
  border-radius: 8px;
  border: 1px solid #4fbbc8;
  padding: 1.25rem 1.25rem;
  position: relative;
  background-color: #fff;
  margin-bottom: 0px;
}

.viewLeadCard {
  border-radius: 8px;
  border: 1px solid #4fbbc8;
  padding: 1.25rem 1.25rem;
  position: relative;
  background-color: #fff;
}

.viewLeadCardMain {
  border-radius: 8px;
  padding: 1.25rem 1.25rem;
  /* height: 99%; */
  position: relative;
  background-color: #fff;
  /* margin-top: -14.5px; */
}

/*********** card start ******/
.viewCard {
  border-radius: 8px;
  border: 1px solid #4fbbc8;
  padding: 1.25rem 1.25rem;
  /* height: 99%; */
  position: relative;
  background-color: #fff;
  /* margin-top: -14.5px; */
}

.cardTopMinusMargin {
  margin-top: -14.5px;
  padding-top: 2rem;
}

.cardTopMinusMarginn {
  margin-top: -23.5px;
  padding-top: 2rem;
}

.viewLabelIcon {
  margin-top: 0.2rem;
  margin-right: 0.8rem;
  color: #718096;
}

.darkBGCard .viewLabelIcon {
  color: #fff;
}

.labelText {
  color: #aaa;
}

.headingLineUp {
  position: relative;
  background-color: #4fbbc8;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  top: -32px;
  z-index: 1;
  /* z-index: 1; */
  left: 0;
  color: #fff;
  display: inline;
  border-radius: 0.25rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-weight: 500;
}

.headingLineUpOut {
  top: 0;
  left: 1.25rem;
  display: inline-block;
}

/*********** preview report end *********/

.submitLeadToolbar {
  display: flex;
  justify-content: flex-end;
}

.topToolbar {
  display: flex;
  justify-content: flex-end;
}

.textareaBox {
  width: 100%;
  border-radius: 8px;
  border-color: rgba(145, 158, 171, 0.32);
}

.textareaBox:hover {
  border-color: rgba(0, 0, 0, 1);
}

.textareaBox:focus {
  border-color: #0d4689;
}

.disbursedText {
  color: #00ad09;
  margin-left: 0.5rem;
}

.rejectedText {
  color: #ce0101;
  margin-left: 0.5rem;
}

.statusBox {
  padding: 0.25rem 1rem;
  display: inline-flex;
  border-radius: 0.5rem;
  background-color: #edf8f9;
  margin-bottom: 1rem;
  border: 1px solid #4fbbc8;
  align-items: center;
  margin-left: 554px;
}

.statusBoxRating {
  padding: 0.25rem 1rem;
  display: inline-flex;
  border-radius: 0.5rem;
  background-color: #edf8f9;
  margin-bottom: 1rem;
  border: 1px solid #4fbbc8;
  align-items: center;
}

.statusBoxSec {
  background-color: #f4eded;
}

/* .statusBoxEdit {padding-right: 6px;} */

.statusEditIcon {
  padding: 0;
  padding-left: 6px;
  border-left: 1px solid #4fbbc8;
  border-radius: unset;
  margin-left: 6px;
  color: #4fbbc8;
}

.statusEditIcon:hover,
.statusEditIcon:focus {
  background-color: unset;
  color: #0d4689;
}
.yellowStatus {
  height: 22px;
  padding: 3px 8px;
  line-height: 0;
  border-radius: 8px;
  color: rgb(183, 129, 3);
  font-size: 0.75rem;
  background-color: rgba(255, 193, 7, 0.16);
  font-weight: 700;
}
.pinkStatus {
  height: 22px;
  padding: 3px 8px;
  line-height: 0;
  border-radius: 8px;
  color: rgb(204, 70, 126);
  font-size: 0.75rem;
  background-color: rgba(255, 193, 7, 0.16);
  font-weight: 700;
}
.blueStatus {
  height: 22px;
  padding: 3px 8px;
  line-height: 0;
  border-radius: 8px;
  color: rgb(37, 94, 250);
  font-size: 0.75rem;
  background-color: rgba(147, 178, 253, 0.16);
  font-weight: 700;
}

/*********login start here ************/

.loginLogo {
  height: 125px;
}

.loginLogoKalolytic {
  height: 40px;
  margin-bottom: 1rem;
}

.mainBtn {
  background-color: #0d4689;
  color: white;
  font-size: 14px;
  padding: 12px 20px;
  letter-spacing: 0.5px;
  font-weight: 500;
  border-radius: 8px;
  display: inline-flex;
  min-width: 100px;
  line-height: unset;
  text-decoration: unset;
  box-shadow: unset;
}

.mainBtn:hover {
  background-color: #4fbbc8;
}

.deleteBtn {
  background-color: #ce0101;
}

.mainBtnOther {
  padding: 6px 12px;
  font-size: 0.875rem;
}

/* .formGroup {margin-bottom: 1.25rem;} */

.formControl {
  /* font-size: 14px; 
    color: #0d4689;
    height:unset !important; */
  background-color: #fff;
  border-radius: 8px;
  padding: 8.5px 10px;
}

.formisedControl {
  /* font-size: 14px; 
    color: #0d4689;
    height:unset !important; */
  background-color: #fff;
  border-radius: 8px;
  padding: 8.5px 10px;
}

.dissabledMenu {
  color: #919eab;
}

.FormLabel {
  color: #718096;
  font-size: 0.8125rem;
  font-weight: 300;
  margin-bottom: 0.5rem;
}

.otherBtn {
  background-color: #4fbbc8;
  padding: 0.5rem 1rem;
}

.deviationBtn {
  background-color: #fa8072;

  padding: 0.5rem 1rem;
}
.deviationBtn:hover,
.deviationBtn:focus {
  background-color: #cd5c5c;
}

.otherBtn:hover,
.otherBtn:focus {
  background-color: #0d4689;
}

.LoginContainer {
  /* background-image: url("../public/static/login/login-bg.jpg");  */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: center;
}

.loginBox {
  margin: auto;
  box-shadow: 1.5px 3.99px 27px 0px rgb(0 0 0 / 10%);
  transition: 0.3s;
  background-color: rgba(255, 255, 255, 0.72);
  align-items: center;
  margin: 1.25rem 0;
}

/* .loginTitle {display: flex; align-items: center; margin-bottom: 1.25rem;} */

.loginTitle img {
  height: 25px;
  margin-left: 8px;
  margin-top: 2px;
}

.loginSubTitle {
  margin-bottom: 2.5rem;
}

.LoginOuter {
  background-color: #eff1f5;
}

.loginInner {
  height: 100vh;
  width: 100%;
  z-index: 999;
}

.formGroupLogin {
  position: relative;
}
.custom-datepicker-popper {
  z-index: 2;
}

.formControlFull {
  border: unset;
  background-color: #fafafc;
  padding: 1rem 1rem 1rem 3rem;
  font-size: 1rem;
  border: 1px solid #d8e4e4;
  border-radius: 8px;
  background-color: #fff;
}

.formControlIcon {
  position: absolute;
  left: 1rem;
  top: 1rem;
  z-index: 999;
  color: #4fbbc8;
}

.formControlFull:focus {
  background-color: #f0f8f7;
  border-color: #4fbbc8;
}

.loginLink {
  color: #4fbbc8;
  text-decoration: unset;
}

.loginLink:hover,
.loginLink:focus {
  color: #4fbbc8;
  text-decoration: unset;
}

.loginBtn {
  /* background: rgb(0,212,255);
    background: linear-gradient(90deg, rgba(0,212,255,1) 0%, rgba(141,218,213,1) 100%); */
  background-color: #4fbbc8;
  color: white;
  font-size: 14px;
  padding: 12px 20px;
  letter-spacing: 0.5px;
  font-weight: 500;
  border-radius: 8px;
  min-width: 100px;
  line-height: unset;
  text-decoration: unset;
  width: 100%;
  transition: all 0.3s;
}

.loginBtn:hover,
.loginBtn:focus {
  background-color: #54d4e2;
  /* background: rgb(141,218,213); */
  /* background: linear-gradient(90deg, rgba(141,218,213,1) 0%, rgba(0,212,255,1) 100%); */
}

.orDivider {
  position: relative;
  text-align: center;
}

.orDivider::before,
.orDivider::after {
  content: '';
  position: absolute;
  top: calc(50% - 0.5px);
  height: 1px;
  width: 42%;
  background-color: #d8e4e4;
}

.orDivider::before {
  left: 0;
}

.orDivider::after {
  right: 0;
}

.loginBanner {
  height: 100vh;
  width: 100%;
  background-image: url('../public/static/login/loginImg.jpg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  color: #fff;
  padding-top: 30px;
  padding-left: 30px;
  display: flex;
  flex-direction: column;
}

.loginBanner:after {
  background-color: rgba(0, 0, 0, 0);
  height: 100%;
  width: 100%;
  content: '';
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  position: absolute;
  z-index: 0;
}

.loginBanner * {
  color: #fff;
  position: relative;
  z-index: 999;
}

.loginRightImg {
  margin: 0 auto;
  max-height: 450px;
}

.loginRightText {
  color: #0d4689;
  text-transform: uppercase;
}

.loginRightTextB {
  color: #0d4689;
  text-transform: uppercase;
}

/******* Login New Style End here ********/

/******* Sign Up Style Start here ********/

.signUpOuter {
  background-color: #eff1f5;
  background-image: linear-gradient(45deg, #0d4689, #0d4689);
  background-size: 500px 100%;
  background-repeat: no-repeat;
  background-position: left;
  position: relative;
}

.signUpInner {
  padding-right: 80px;
  padding-left: 420px;
  padding-top: 0;
  padding-bottom: 0;
  overflow: auto;
  height: 100vh;
}

.signUpLeft {
  position: fixed;
  left: 80px;
  top: 0;
  bottom: 0;
  width: 420px;
  z-index: 999;
}

.signUpLogo {
  height: 2.5rem;
  width: auto;
}

.signUpLogoKalolytic {
  height: 40px;
  width: auto;
}

.signUpleftInner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 0;
  z-index: 1;
  height: 100%;
}

.docList {
  margin-bottom: 2rem;
}

.docListItem {
  margin-top: 1.25rem;
  font-size: 1rem;
  color: #fff;
}

.docListItem:last-of-type {
  margin-bottom: none;
}

.docListItemm {
  margin-top: 0.5rem;
}

.docListText {
  font-size: 1rem;
}

.readyBtn {
  background-color: #fff;
  color: #0d4689;
  padding: 0.5rem 1rem;
  font-size: 0.8125rem;
  border-radius: 0.25rem;
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  line-height: 1.5;
  border: 1px solid #fff;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
}

.readyBtn:hover {
  background-color: transparent;
  color: #fff;
}

.signUpRight {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.signUpRightInner {
  position: relative;
}

.signUpHeading {
  color: #4fbbc8;
  /* color: #fff; */
  width: 320px;
}

.signUpRight .video-react .video-react-video,
.signUpRight .video-react .video-react-poster,
.signUpRight .video-react.video-react-fluid {
  border-radius: 0.75rem;
  border: unset;
  outline: unset;
}

.signUpRight .video-react-has-started .video-react-control-bar {
  border-radius: 0 0 0.75rem 0.75rem;
  z-index: 999;
}

.signUpRight .video-react .video-react-big-play-button {
  top: calc(50% - 22.5px);
  left: calc(50% - 45px);
  z-index: 9999;
}

.customSelectPlaceholder {
  color: gray; /* Change the color to your desired color */
}
.closeVideoOut {
  position: absolute;
  right: -1rem;
  top: -1rem;
  background-color: #0d4689;
  border-radius: 50%;
  height: 2rem;
  width: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.closeVideoIcon {
  font-size: 0.8125rem;
  color: #fff;
}

.signUpFormRight {
  background-color: #fff;
  border-radius: 0.75rem;
  padding: 4rem 5rem;
  min-height: 450px;
}

.signUpFormStep {
  display: flex;
  align-items: center;
  flex-direction: row;
  cursor: pointer;
}

.signUpFormStep {
  text-decoration: none;
  color: #fff;
}

.signUpFormStep:hover,
.signUpFormStep.active {
  color: #4fbbc8;
}

.signUpFormTextBox {
  width: 310px;
  padding-right: 1rem;
}

.signUpFormText {
  font-size: 1rem;
  font-weight: 500;
}

.signUpFormIconOut {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  box-shadow: 0 2px 4px 0 rgb(208 208 208 / 50%);
  background-color: #ffffff;
  color: rgba(0, 0, 0, 0.54);
  display: flex;
  align-items: center;
  justify-content: center;
}

.signUpFormStep:hover .signUpFormIconOut,
.signUpFormStep.active .signUpFormIconOut {
  background-color: #4fbbc8;
  color: #fff;
}

.signUpFormIconOutActive {
  background-color: #4fbbc8;
}

/* .signUpFormIcon {
} */

.signUpFormTextSec {
  /* color: #4fbbc8; */
  color: #718096;
}

.partnerTypeCard {
  border: 1px solid #eaedf1;
  border-radius: 0.5rem;
  padding: 1rem;
  width: 150px;
  display: flex;
  height: 150px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.partnerTypeCard.active {
  background-color: #4fbbc8;
  color: #fff;
  border-color: #4fbbc8;
}

.partnerTypeCard:hover {
  border-color: #4fbbc8;
}

.uploadDocBoxLabel {
  border: 1px solid #eaedf1;
  border-radius: 0.5rem;
  padding: 1rem;
  width: 150px;
  display: flex;
  height: 150px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.uploadDocBoxLabel:hover {
  border-color: #4fbbc8;
}

.colorGreen {
  color: #4fbbc8;
}

.backgroundGreen {
  background-color: #4fbbc8 !important;
  color: #fff !important;
}

.colorWhite {
  color: #fff;
}

.uploadDocBoxLabelFull {
  width: auto;
  height: 250px;
}

.uploadDocBoxLabelSec {
  height: 37px;
  width: auto;
  margin-right: 0;
  padding: 0.5rem;
}

.uploadedImg {
  height: 18px;
  width: auto;
  max-width: unset;
  font-size: 1rem;
}

.UploadIconBox {
  line-height: 0;
}

/******* Sign Up Style End here ********/

/*********** timeline **********/

.timelineB {
  position: relative;
  overflow-x: auto;
  align-items: flex-start;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-flow: row;
  width: 100%;
}

.timelineContentB::after {
  position: absolute;
  content: '';
  height: 2px;
  background-color: #eaedf1;
  top: 2.25rem;
  left: 0;
  right: 0;
}

.timelineContentB {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 210px;
  text-align: center;
  position: relative;
  padding: 0 1rem 1rem;
}

.timelineContentB::before {
  position: absolute;
  content: '';
  width: 16px;
  height: 16px;
  border-radius: 50%;
  top: 1.8rem;
  left: calc(50% - 8px);
  background-color: #eaedf1;
  z-index: 999;
}

.timelineContentBGreen::before {
  background-color: #00ad09;
}

.timelineContentBOrange::before {
  background-color: #f36900;
}

.date {
  margin-bottom: 2rem;
}

/*********** timeline **********/

.statusB {
  padding: 0.5rem 1rem;
  display: flex;
  border-radius: 0.5rem;
  background-color: #eef4ed;
  margin-bottom: 1.5rem;
}

.mainBtnSmall {
  width: 75px;
  margin: 0 auto;
}

.mainBtnSmalll {
  padding: 8px 16px;
  min-width: 80px;
}
.mainBtnSmall1 {
  padding: 8px 16px;
  min-width: 75px;
}

/******** review start ********/

.cardOuter {
  border-radius: 0.5rem;
  padding: 1rem;
  border: 1px dashed #eaedf1;
  background-color: #fff;
}

.profileImg {
  height: 175px;
  border: 1px solid #eaedf1;
  border-radius: 0.25rem;
  margin-bottom: 0.5rem;
}

.circleProgress {
  position: relative;
  width: 120px;
  height: 120px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.circleProgress::after {
  content: '';
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 8px solid #eee;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.progressValue {
  position: absolute;
  top: calc(60px - 18px);
  left: calc(60px - 23px);
}

.documentImg {
  border: 6px solid #f8f8f8;
  box-shadow: 0 0 20px -8px rgb(0 0 0 0/ 10%);
  border-radius: 4px;
  width: 100%;
}

.profileImgSec {
  border: 1px solid #eaedf1;
  border-radius: 0.25rem;
  margin-bottom: 0.5rem;
}

.viewInnerCard {
  background-color: #f3f5f7;
  border-radius: 0.5rem;
  padding: 1rem;
}

.mainBtnSec {
  font-size: 12px;
  padding: 0.4rem 0.75rem;
  background: #dae0f5;
  color: #0d4689;
  /* border: 1px solid #0d4689; */
}

.mainBtnSec:hover,
.mainBtnSec:focus {
  background-color: #0d4689;
  color: #fff;
}

.mainBtn1 {
  background-color: #0d4689;
  color: white;
  font-size: 14px;
  padding: 10px 20px;
  letter-spacing: 0.5px;
  font-weight: 500;
  border-radius: 8px;
  display: inline-flex;
  min-width: 100px;
  line-height: unset;
  text-decoration: unset;
  box-shadow: unset;
}
.mainBtn1:hover,
.mainBtn1:focus {
  background-color: #4fbbc8;
}

.mainBtnThird {
  background: #dae0f5;
  color: #0d4689;
  font-size: 12px;
  padding: 0.5rem 0.75rem;
}
.mainBtnThird:hover,
.mainBtnThird:focus {
  background-color: #0d4689;
  color: #fff;
}

.file-input {
  display: none;
}

.file-input-label {
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  border-radius: 4px;
  background-color: #1976d2;
  color: white;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
  border: 1px solid transparent;
  font-size: 14px;
}

.file-input-label:hover {
  background-color: #1565c0;
}

.greenBtn1 {
  background-color: #00ad09;
  color: #fff;
}
.mainBtnDisabled {
  background-color: gray;
  padding: 10px 20px;
  cursor: not-allowed;
  opacity: 0.5;
}
/* .formGroup {margin-bottom: 1.25rem;} */
/* .formControl {padding-top: 10px; padding-bottom: 10px; font-size: 14px; color: #2e3192; height:unset !important;} */
.dissabledMenu {
  color: #919eab;
}
/* .FormLabel {color: #999;} */

.otherBtn {
  background-color: #4fbbc8;
  padding: 0.5rem 1rem;
}
.otherBtnSmall {
  background-color: #4fbbc8;
  min-width: unset;
  padding: 5px 10px;
}

.otherBtn:hover,
.otherBtn:focus {
  background-color: #0d4689;
}

.mainBtnBig {
  padding: 16px 20px;
}

.deleteBtn {
  background-color: #ce0101;
}

.mainBtnSec1 {
  background-color: #4fbbc8;
}

.mainBtnSec1:hover,
.mainBtnSec1:focus {
  background-color: #0d4689;
}

.bankStatementBox {
  border-radius: 0.5rem;
  padding: 0.5rem 0.75rem;
  margin-bottom: 1rem;
  top: 0px;
  position: relative;
  background-color: #fff;
  /* background-color: #dae0f5; */
  cursor: pointer;
  transition: all 0.2s ease-out;
  width: 200px;
  height: 60px;
  border: 1px solid #4fbbc8;
}

.bankStatementBox.selected {
  background-color: #0d4689;
  color: #fff;
}

.bsStatus {
  position: absolute;
  top: -0.5rem;
  right: -0.5rem;
  background-color: #4fbbc8;
  color: #fff;
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.bsStatusGreen {
  background-color: #a6ce39;
}

.leftBox {
  flex: 1 1;
  padding: 0.75rem;
}

.rightBox {
  border-left: 1px solid #4fbbc8;
  width: 300px;
  padding: 0.75rem;
}

/******** review end ********/

/********wizrd css start here **********/

.stepWizardOuter {
  background: #e2e8f0;
  border-radius: 0.5rem;
}

.stepWizardOuter .stepWizard {
  padding: 1rem;
  border-radius: 0.5rem;
  /* margin-right: 2rem; */
}

.stepWizardOuterWrapFlex {
  /* flex-wrap: wrap; */
  overflow: auto;
}

.stepWizardOuterWrapFlex .stepWizard {
  padding: 0.5rem;
}

.MuiStepIcon-root {
  color: rgba(0, 0, 0, 0.38);
  display: block;
  color: rgba(0, 0, 0, 0.38);
  display: block;
}

.stepWizardLabel .css-xhca2s-MuiStepLabel-label {
  font-size: 12px;
}

.previousBtn {
  background-color: #f9f9f9;
  color: #2e3192;
}

.previousBtn:hover,
.previousBtn:focus {
  background-color: #e9e9e9;
  color: #2e3192;
}

.nextBtn {
  margin-left: auto;
}

.uploadDummyImg {
  height: 100px;
  margin-bottom: 1.5rem;
  display: inline-block;
  border-radius: 8px;
  border: 1px solid #f0f0f0;
}

.uploadCardLink {
  justify-content: center;
  align-items: center;
}

.homeCardLink.uploadCardLink .linkIcon {
  margin-left: 3px;
  font-size: 21px;
}

.loginOtpList {
  font-size: 14px;
}

.docIcon {
  font-size: 1.25rem;
  color: #2e3192;
}

.docIconn {
  font-size: 1.25rem;
  color: #0d4689;
}

.tableHead {
  background-color: #f9f9f9;
}

.docApplicantName {
  color: #2e3192;
  font-size: 1rem;
}

.setevenodd table:nth-child(even) tr {
  background: #fff;
}

.issEnableSubCardCls {
  margin-top: 20px;
  margin-left: 4px;
}

.issEnableSubCardCls tr td {
  padding: 10px !important;
}

/* .issEnableSubCardCls,
.issEnableSubCardCls div .scrollCls {
  width: 100%;
  float: left;
}

.issEnableSubCardCls div {
  width: 100%;
} */

.outerImageReviewDoneIcon {
  position: absolute;
  border-radius: 12px;
  right: 0;
  top: -12px;
  width: 20px;
  height: 20px;
  line-height: 24px;
  text-align: center;
  background-color: #00ad09;
}

.outerImageReviewDoneIcon.orangeBG {
  background-color: #f36900;
}

.outerImageReviewDoneIcon.greenBG {
  background-color: #00ad09;
}

.ImageReviewDoneIcon {
  color: #fff;
  font-size: 14px;
}

.outerImageReviewRemainIcon {
  background-color: #ce0101;
  border-radius: 12px;
  position: absolute;
  right: 0;
  top: -12px;
  width: 20px;
  height: 20px;
  line-height: 24px;
  text-align: center;
}

.ImageReviewRemainIcon {
  color: #fff;
  font-size: 14px;
}

.viewLeadCardTableInner {
  padding: 1.5rem 0 0;
  overflow: hidden;
}

.uploadFilePreview,
.uploadPopupRow {
  border: 1px solid #f0f0f0;
  border-radius: 0.5rem;
  padding: 12px;
  margin-bottom: 15px;
}

.stepWizardLabel .css-xhca2s-MuiStepLabel-label {
  font-size: 12px;
}

.previousBtn {
  background-color: #f9f9f9;
  color: #2e3192;
}

.previousBtn:hover,
.previousBtn:focus {
  background-color: #e9e9e9;
  color: #2e3192;
}

.nextBtn {
  margin-left: auto;
}

.uploadDummyImg {
  height: 100px;
  margin-bottom: 1.5rem;
  display: inline-block;
  border-radius: 8px;
  border: 1px solid #f0f0f0;
}

.uploadCardLink {
  justify-content: center;
  align-items: center;
}

.homeCardLink.uploadCardLink .linkIcon {
  margin-left: 3px;
  font-size: 21px;
}

.loginOtpList {
  font-size: 14px;
}

.docIcon {
  font-size: 1.25rem;
  color: #2e3192;
}

.docIconn {
  font-size: 1.25rem;
  color: #0d4689;
}

.tableHead {
  background-color: #f9f9f9;
}

.docApplicantName {
  color: #2e3192;
  font-size: 1rem;
}

.setevenodd table:nth-child(even) tr {
  background: #fff;
}

.issEnableSubCardCls {
  margin-top: 20px;
  margin-left: 4px;
}

.issEnableSubCardCls tr td {
  padding: 10px !important;
}

.issEnableSubCardCls,
.issEnableSubCardCls div .scrollCls {
  width: 100%;
  float: left;
}

.issEnableSubCardCls div {
  width: 100%;
}

.outerImageReviewDoneIcon {
  position: absolute;
  border-radius: 12px;
  right: 0;
  top: -12px;
  width: 20px;
  height: 20px;
  line-height: 24px;
  text-align: center;
  background-color: #00ad09;
}

.outerImageReviewDoneIcon.orangeBG {
  background-color: #f36900;
}

.ImageReviewDoneIcon {
  color: #fff;
  font-size: 14px;
}

.uploadFilePreviewOrange {
  border-color: #f36900;
}

.uploadFilePreview,
.uploadPopupRow {
  border: 1px solid #f0f0f0;
  border-radius: 0.5rem;
  padding: 12px;
  margin-bottom: 15px;
}

.uploadFilePreview {
  background-color: #edf8f9;
  cursor: pointer;
  min-height: 120px;
  margin-right: 1rem;
  padding: 0.5rem;
  text-align: center;
  transition: all 0.3s;
  width: 100px;
}

.uploadPopupRoww {
  padding: 0.5rem;
  background-color: #fafbfc;
  border: 1px solid #f0f0f0;
  border-radius: 0.5rem;
  margin-bottom: 0.5rem;
}

.uploadFilePrevieww {
  border: 1px solid #f0f0f0;
  border-radius: 0.5rem;
  position: relative;
}

.uploadFilePrevieww {
  background-color: #edf8f9;
  cursor: pointer;
  height: 100px;
  margin-right: 1rem;
  padding: 0.5rem 0.5rem;
  text-align: center;
  transition: all 0.3s;
  width: 90px;
}

.docName {
  line-height: 16px;
  font-size: 0.75rem;
  height: 32px;
  overflow: hidden;
}

.ImageViewIcon {
  font-size: 1.5rem;
}

.uploadFilePreviewGreen {
  border-color: #00ad09;
}

.uploadFilePreviewRed {
  border-color: #ce0101;
}

.uploadFilePreview:hover {
  background-color: #fff;
  box-shadow: 0 0 20px -8px rgba(0, 0, 0, 0.2);
}

.UploadPopupIcon {
  font-size: 1.25rem;
  margin-right: 1rem;
  width: 40px;
  height: 40px;
  text-align: center;
  background-color: #fff;
  border-radius: 1.25rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 10px -2px rgb(0 0 0 / 10%);
  -webkit-box-shadow: 0 0 10px -2px rgb(0 0 0 / 10%);
}

.UploadPopupIcon img {
  width: 28px;
  display: inline;
}

.UploadIcon {
  font-size: 28px;
  color: #2e3192;
}

.Fileupload {
  margin-left: 1rem;
}

.PassProtectB {
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  flex-direction: row;
}

.uploadPopupInnerRow {
  display: flex;
  align-items: center;
}

.outerTableBox {
  border: 1px solid #f0f0f0;
  border-radius: 0.5rem;
}

.documentUploadedBox {
  padding: 1rem;
}

.formControlRemark {
  padding: 6px 10px;
}

.popupHeading {
  border-bottom: 1px solid #2e3192;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.popupHeadingg {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border-bottom: 1px solid #eaedf1; */
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.formLabel1 {
  margin-bottom: 0.5rem;
}

.hideForView {
  display: none;
}

.TextField {
  border: 1px solid #f0f0f0;
  border-radius: 0.5rem;
  padding: 0.4rem 0.75rem;
  background-color: #fff;
  font-size: 0.875rem;
}

.uploadBtn {
  background-color: #2e3192;
  color: #fff;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  margin-left: 0.75rem;
}

.uploadBtn:hover,
.uploadBtn:focus {
  background-color: #ec3a46;
}

.TextFieldGroup {
  border: unset;
}

.FileTypeName {
  white-space: nowrap;
}

/********wizrd css end here **********/

/********* integration card design *********/

.cardOuterGreenBorder {
  border: 1px dashed #4fbbc8;
  padding: 0;
}

.cardTop {
  padding: 1rem;
}

.cardTop {
  display: flex;
  align-items: center;
}

.cardToppest {
  padding: 1rem;
}

.cardIconIcon {
  /* align-items: center;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0 5px 10px rgb(0 0 0 / 6%);
    color: #0f478a;
    display: flex;
    height: 80px;
    justify-content: center;
    overflow: hidden;
    text-align: center;
    white-space: nowrap;
    width: 80px; */
  font-size: 2rem;
}

.cardIconInner {
  align-items: center;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 5px 10px rgb(0 0 0 / 6%);
  /* color: #0f478a; */
  display: flex;
  height: 64px;
  justify-content: center;
  overflow: hidden;
  text-align: center;
  white-space: nowrap;
  width: 64px;
  margin-right: 1rem;
}

.cardBottom {
  border-top: 1px dashed #4fbbc8;
  padding: 0.1rem 0.5rem;
}

.facebook {
  color: #3b5998;
}

.instagram {
  color: #fccc63;
}

.youtube {
  color: #ff0000;
}

.callCenter {
  color: #4fbbc8;
}

.cardText {
  font-size: 0.875rem;
}

.disableBox {
  pointer-events: none;
  opacity: 0.4;
}

.applicantImg {
  height: auto;
  width: 100%;
  max-width: 200px;
  border: 1px solid #eaedf1;
  border-radius: 0.25rem;
  margin-bottom: 0.5rem;
}

.statusLink {
  color: #4fbbc8;
  font-size: 11px;
  cursor: pointer;
  font-weight: 600;
  text-decoration: underline;
}

.statusLink:hover {
  color: #0d4689;
  font-size: 11px;
  cursor: pointer;
}

.tableBox {
  border-radius: 8px;
  overflow: hidden;
  background-color: #fff;
}

.cardTopMinusMarginTable {
  margin-top: -14.5px;
  padding-top: 1.5rem;
}

.previewImage {
  max-height: 208px;
  width: 100%;
}

.subHeading {
  border: 1px solid #4fbbc8;
  background-color: #edf8f9;
  border-radius: 0.5rem;
  padding: 0.5rem;
  text-align: center;
}

.sliderPaperB {
  border-right: unset;
}

.greenBtn {
  background-color: #a6ce39;
}

.greenBtn:hover {
  background-color: #bceb3e;
}

.redBtn {
  background-color: #ef3d46 !important;
  color: #fff !important;
}

.redBtn:hover {
  background-color: #ff848a !important;
}

.decisionImg {
  filter: invert(1);
}

.actionButton:hover .decisionImg {
  filter: invert(0);
}

.textInputSmall {
  background-color: unset !important;
  border: unset;
  color: #fff;
  border: unset !important;
}

.dateStatusB {
  background-color: #f0f8f7;
  border: 1px solid #e2e8f0;
  border-radius: 4px;
  padding: 6px;
}

.yesNoBtn,
.yesNoBtn:hover,
.yesNoBtn:focus {
  background-color: #edf8f9;
  letter-spacing: 0.5px;
  font-weight: 500;
  border-radius: 8px;
  color: #0d4689;
}

.yesNoBtn.active {
  background-color: #0d4689;
  color: #fff;
}

.extraTopIcon {
  border-radius: 8px;
  position: absolute;
  right: 1rem;
  top: 1rem;
}

.successStatus {
  height: 22px;
  line-height: 0;
  border-radius: 8px;
  padding: 3px 8px;
  color: #229a16;
  font-size: 0.75rem;
  background-color: rgba(84, 214, 44, 0.16);
  font-weight: 700;
}
.rejectedStatus {
  height: 22px;
  padding: 3px 8px;
  line-height: 0;
  border-radius: 8px;
  color: #b72136;
  font-size: 0.75rem;
  background-color: rgba(255, 72, 66, 0.16);
  font-weight: 700;
}

.directorPic {
  border: 1px solid #eaedf1;
  height: 200px;
  width: 200px;
  border-radius: 100px;
  overflow: hidden;
}

.messageFrom {
  font-family: 'Great Vibes', cursive;
  letter-spacing: 1;
  font-weight: 300;
}

.DirectorName {
  text-decoration: underline;
  text-transform: uppercase;
  letter-spacing: 0.5;
}

.directorImgBox {
  padding-left: 150px;
}

.directPositionBox {
  padding-left: 150px;
}

/*Loading css start from here*/

.loader-title {
  font-size: 14px;
  font-weight: normal;
}

.loader-content .loaderBar {
  text-align: center;
}

/*Loading css end*/

/* tabs  */
.tabOuter {
  min-height: unset;
}
.tabOuterSec {
  padding: 0.5rem 1rem 0;
}
.tabDefaultSecurity {
  /* border: 1px solid #4fbbc8;  */
  border-radius: 0.25rem;
  background-color: rgb(79, 187, 200, 0.4);
  padding: 0.5rem;
  font-size: 0.8175rem;
  min-height: unset;
  line-height: unset;
  color: #fff;
  margin-right: 1rem;
  font-weight: normal;
  min-width: 140px;
}
.tabDefault.tabSelected {
  background-color: #4fbbc8;
  color: #fff;
}
.tabDefaultSec {
  /* border: 1px solid #4fbbc8;  */
  border-radius: 0.25rem;
  background-color: rgb(79, 187, 200, 0.4);
  padding: 0.25rem 0.5rem;
  font-size: 0.8175rem;
  min-height: unset;
  line-height: unset;
  color: #fff;
  margin-right: 0.75rem;
  font-weight: normal;
  /* min-width: 140px; */
}
.tabDefaultSec.tabSelected {
  background-color: #4fbbc8;
  color: #fff;
}
.tabIndicator {
  display: none;
}
.tabFlexPosition {
  justify-content: right;
}
.tabDefaultThird {
  border-bottom: 2px solid transparent;
  font-size: 0.8175rem;
  font-weight: 500;
  line-height: unset;
  margin-right: 1rem;
  min-height: unset;
  min-width: unset;
  padding: 0.2rem 0;
}
.tabDefaultThird.tabSelectedThird {
  border-color: #0d4689;
  color: #0d4689;
  font-weight: 600;
}
/* tabs  */

.ck-editor__editable figure,
.ck-editor__editable p {
  width: 100%;
  float: left;
}

.reviewEditIcon {
  position: absolute;
  top: 1rem;
  right: 1rem;
  border-radius: 8px;
}

.reviewEditIcon .squareIconButton {
  border: 1px solid #4fbbc8;
  background-color: #4fbbc8;
  color: #fff;
}

.reviewEditIcon .squareIconButton:hover {
  background-color: #fff;
  color: #4fbbc8;
  border: 1px solid #4fbbc8;
}

.textAlignRight {
  text-align: right;
}
.yesNoBtn,
.yesNoBtn:hover,
.yesNoBtn:focus {
  background-color: #edf8f9;
  letter-spacing: 0.5px;
  font-weight: 500;
  border-radius: 8px;
  color: #0d4689;
}

.yesNoBtn.active {
  background-color: #0d4689;
  color: #fff;
}

.photo-viewer-container {
  width: 100% !important;
  height: auto !important;
}

.pg-viewer-wrapper {
  overflow: auto;
  overflow-y: auto !important;
}

.pdf-viewer {
  height: 200px;
}

.textCenter {
  text-align: center;
}

.pdfBox {
  overflow: hidden;
}

.pdfBox .MuiBox-root {
  overflow-y: scroll;
}

.pdfBox .MuiBox-root,
.pdfBox .react-pdf__Document,
.pdfBox .react-pdf__Page__canvas,
.pdfBox .react-pdf__Page,
.pdfBox .react-pdf__Page__textContent {
  width: 100% !important;
  float: left;
}

.pdfBoxreview {
  overflow: hidden;
  height: 200px;
}

.pdfBoxreview .react-pdf__Document {
  overflow-y: scroll;
}

.pdfBoxreview .react-pdf__Document,
.pdfBoxreview .react-pdf__Page,
.pdfBoxreview .react-pdf__Page__canvas {
  height: 100%;
  width: 100% !important;
  float: left;
}

.pickBox {
  width: 280px;
  float: left;
  overflow: hidden;
  height: 200px;
}

.pickBox .react-pdf__Page {
  overflow-y: scroll;
}

.pickBox .react-pdf__Page,
.pickBox .react-pdf__Page__canvas,
.pickBox .react-pdf__Page {
  height: 100%;
  width: 100% !important;
  float: left;
}

.popBoxelm.color-blue {
  width: 100%;
  float: left;
  font-size: 15px;
  text-align: center;
  font-weight: 600;
  color: #27295f !important;
}

.btncustome {
  margin-top: 15px;
  width: 100%;
  display: block;
  text-align: center;
}

.mainBtn.restart {
  background: #f0f0f0;
  margin-right: 10px;
  color: #27295f !important;
}

.mainBtn.restart:hover {
  background: #27295f;
  color: #fff !important;
}

.morezind {
  z-index: 999999;
}

/* tabs  */

.tabOuter {
  min-height: unset;
}

.tabOuter1 {
  min-height: unset;
}
.tabDefault {
  border: 1px solid #4fbbc8;
  border-radius: 0.25rem;
  background-color: #fff;
  padding: 0.5rem;
  font-size: 0.8175rem;
  min-height: unset;
  line-height: unset;
  color: #4fbbc8;
  margin-right: 1rem;
  font-weight: normal;
}

.tabDefault1 {
  /* border: 1px solid #4fbbc8;  */
  border-radius: 0.25rem;
  background-color: rgb(79, 187, 200, 0.4);
  padding: 0.5rem;
  font-size: 0.8175rem;
  min-height: unset;
  line-height: unset;
  color: #fff;
  margin-right: 1rem;
  font-weight: normal;
  min-width: 160px;
}

.tabDefault.tabSelected {
  background-color: #4fbbc8;
  color: #fff;
}

.tabDefault1.tabSelected {
  background-color: #4fbbc8;
  color: #fff;
}

.tabIndicator {
  display: none;
}

.validateIconBefore {
  color: #919eab;
}

.validateIconAfter {
  color: #00ad09;
}

.deleteBox {
  position: absolute;
  top: -14px;
  right: 14px;
}
body .deleteBtn {
  background-color: #ce0101;
  color: #fff;
  border-color: #ce0101;
  padding: 0.25rem;
}
body .deleteBtn:hover {
  background-color: #f51818;
  color: #fff;
  border-color: #f51818;
  padding: 0.25rem;
}

.editIconTable {
  color: #4fbbc8;
  position: absolute;
  right: 0;
  padding: 6px;
}
.colorPalate {
  height: 36px;
  width: 36px;
  padding: 0;
  border-radius: 0.5rem;
}

.chipBox {
  border: 1px solid #e0e0e0;
  padding: 5.5px 50px 5.5px 10px;
  border-radius: 0.5rem;
  background-color: #fff;
  position: relative;
  width: 100%;
}

.searchIconB {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 0 0.5rem 0.5rem 0;
  margin: 0;
}

.innerBoxBg {
  background-color: #f3f5f7;
  border-radius: 0.5rem;
  padding: 1rem;
}

.chipCom {
  font-size: 0.75rem;
}

.bankSelectCheck {
  min-width: 100px;
}

.circleLine {
  border: 10px solid #00ad09;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/*=========journey css =========*/

.mar_b_0 {
  margin-bottom: 0px !important;
}

.mainBtn.deleteBtn:hover {
  background-color: #ce0101 !important;
}

body .disabledDocument {
  pointer-events: none;
  opacity: 0.3;
}

body .enabledDocument {
  pointer-events: all;
  opacity: 1;
}

.followUpIcon {
  animation: blink 0.5s linear infinite;
  padding: 0;
  background-color: transparent;
  margin-left: 0.5rem;
  color: #0d4689;
  display: inline;
}

@keyframes blink {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

.grayBGCard {
  background-color: #cfd3dc;
}

.redColor {
  color: #d21c1c;
}

.greenColor {
  color: #00ad09;
}

.darkBGCard {
  background-color: #4fbbc8;
  color: #fff;
}

.darkBHeadingUp {
  background-color: #fff;
  color: #4fbbc8;
}

.profileImageBox {
  width: 90px;
  height: 100px;
}

.profileImage {
  border: 2px solid #fff;
  border-radius: 50%;
  overflow: hidden;
  width: 90px;
}

.profileName {
  background-color: #fff;
  color: #4fbbc8;
  border-radius: 20px;
  position: relative;
  top: -10px;
  width: 100%;
  text-align: center;
  font-size: 12px;
  line-height: 20px;
}

.fontBold {
  font-weight: 700;
}
.fontBold1 {
  font-weight: bold;
}

.textDecoration {
  text-decoration: underline;
}

.topToolbarIconBox {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 1rem;
}

.cardSmallNew,
.profileName {
  background-color: #fff;
  position: relative;
}

.cardSmallNew {
  border: 1px solid #4fbbc8;
  border-radius: 8px;
  min-width: 140px;
  padding: 1rem;
}

.cardSmallNew:before {
  background-color: #4fbbc8;
  border-radius: 2px;
  content: '';
  height: 50px;
  left: -3px;
  position: absolute;
  top: 10px;
  width: 5px;
}

.cardSmallSecond {
  padding: 0.5rem 0.75rem;
  min-width: 100px;
}

.cardSmallSecond::before {
  height: 35px;
  top: 8px;
  content: '';
}

.cardSmallNew:hover {
  box-shadow: 0 0 5px 0 #4fbbc8;
}

.reviewBtnBox .reviewBtn {
  color: #ef3d46;
}

.reviewBtnBox .reviewBtn:hover {
  color: #fff;
}

.taskCardBox {
  background-color: #fff;
  border: 1px solid #4fbbc8;
  border-radius: 0.5rem;
  overflow: hidden;
}

.taskCardTop {
  padding: 0.5rem;
  border-bottom: 1px solid #edf8f9;
  display: flex;
  font-size: 10px;
  justify-content: space-between;
}

.taskCardBBottom {
  padding: 0.5rem;
  border-top: 1px solid #4fbbc8;
}

.tctText {
  font-size: 12px;
}

.taskCardBottom {
  background-color: #718096;
  border-radius: 50% 50% 0.45rem 0.45rem;
  padding: 0.75rem;
  text-align: center;
  width: 100%;
}

.taskRedButton {
  background-color: #ef3d46;
}

.taskRedButton:hover {
  background-color: #d60c17;
}

.taskGreenButton,
.taskGreenButton:hover {
  background-color: #a6ce39;
}

.tcbText {
  font-weight: bold;
  color: #fff;
}

.cardIcon {
  border: 2px solid #edf8f9;
  border-radius: 0.5rem;
  cursor: pointer;
  padding: 1rem;
  text-align: center;
}

.bestOffer {
  position: absolute;
  top: 5px;
  left: 0;
  background: #166bac;
  padding: 0px 10px 0 8px;
  color: #fff;
  font-size: 0.6rem;
  text-transform: uppercase;
  line-height: 20px;
  font-weight: 600;
  border-radius: 0 10px 10px 0;
  letter-spacing: 0.5px;
}

.mailSMSType {
  border: 1px solid #eaedf1;
  border-radius: 0.5rem;
  padding: 1rem;
  width: 75px;
  display: flex;
  height: 75px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.mailSMSType.active {
  background-color: #edf8f9;
  color: #fff;
  border-color: #4fbbc8;
}

.mailSMSType:hover {
  border-color: #4fbbc8;
}

.verifyBtn,
.verifiedBtn {
  position: absolute;
  bottom: 0;
  right: 0;
  min-width: unset;
  font-size: 0.75rem;
  padding: 0.6rem 16px;
}

.verifiedBtn,
.verifiedBtn:hover,
.verifiedBtn:focus {
  background-color: #3edd46;
  color: #fff !important;
}

.linearProgressBox {
  background-color: #4fbbc8;
  color: #fff;
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
}

.followBox {
  background-color: #e2e0f0;
  padding: 4px;
  border-radius: 0.25rem;
  text-align: center;
  cursor: pointer;
}

.completedBox {
  background-color: #e3f0e0;
  padding: 4px;
  border-radius: 0.25rem;
  text-align: center;
  cursor: pointer;
}

.appointmentBox {
  background-color: #f0e0e0;
  padding: 4px;
  border-radius: 0.25rem;
  text-align: center;
  cursor: pointer;
}

.bankRecoBox {
  background-image: url('../public/static/congrats-img2.webp');
  background-position: center;
  background-size: 100% auto;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 0.5rem;
}

.bankRecoBoxSorry {
  /* background-color: #ffbcbc5d; */
  background-position: center;
  background-size: 100% auto;
  background-repeat: no-repeat;
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 0.5rem;
}

.tools {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

body .zoomBtns {
  padding: 4px;
}

body .fullScreenBtn {
  padding: 4px;
}

body .fullScreenBtn:hover,
body .fullScreenBtn:focus {
  background-color: #0d4689;
}

.logoclass img {
  height: 160px;
  width: 100%;
}

.logoclass {
  text-align: center;
  display: flex;
  justify-content: center;
}

.logoB img {
  height: 75px;
}

.logoB {
  text-align: center;
  display: flex;
  justify-content: center;
}

.uploadFilePreviewBox {
  border: 1px solid #f0f0f0;
  border-radius: 0.5rem;
  position: relative;
  background-color: #edf8f9;
  cursor: pointer;
  min-height: 120px;
  margin-right: 1rem;
  padding: 0.5rem 0.5rem;
  text-align: center;
  transition: all 0.3s;
  width: 90px;
}

.uploadFilePreviewBox:hover {
  background-color: #fff;
  box-shadow: 0 0 20px -8px rgba(0, 0, 0, 0.2);
}

.deleteDoc {
  position: absolute;
  top: -8px;
  right: -8px;
  padding: 0;
  background: #ce0101;
  color: #fff;
}

.deleteDoc:hover,
.deleteDoc:focus {
  background: #ff0000;
}

.notfirstElement,
.firstElement {
  border-bottom: 1px solid #f0f0f0;
  border-left: 1px solid #f0f0f0;
  border-right: 1px solid #f0f0f0;
}

.whenValue {
  width: 135px;
  position: absolute;
  top: 3px;
  left: 5px;
  padding: 5px;
  background: #fff;
  z-index: 2;
  color: #ddd;
}

.signUpLogo {
  height: 2.5rem;
  width: auto;
}

.mainHeader {
  width: 100%;
  background-position: bottom;
  /* background: linear-gradient(90deg,#27295f 0,#166bac 50%,#27295f); */
  padding-top: 6px;
  padding-bottom: 6px;
  border-bottom: 1px solid #f0f0f0;
  /* background: 'linear-gradient(to right, rgba(46,49,146,1), rgba(238,58,70,1))', */
  /* boxShadow: '0 0px 15px 2px rgb(0 0 0 / 10%)', */
  background-color: rgb(13, 70, 137);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.mainLogo {
  height: 40px;
}

.footer {
  background-color: #fff;
  border-top: 1px solid #eaedf1;
  bottom: 0;
  left: 0;
  padding: 8px 20px;
  position: fixed;
  right: 0;
  text-align: center;
  width: 100%;
  z-index: 999;
}

.reviewEditIconn {
  border-radius: 8px;
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 999;
}

.reviewEditIconn .squareIconButton {
  padding: 2px;
  background-color: #4fbbc8;
  color: #fff;
}

.reviewEditIconn .squareIconButton:hover {
  padding: 2px;
  background-color: #fff;
  color: #4fbbc8;
  border-color: #4fbbc8;
}

.reviewEditIconn .squareIconBtnGreen {
  background-color: #a6ce39;
  color: #fff;
}

.reviewEditIconn .squareIconBtnGreen:hover {
  border-color: #a6ce39;
  color: #a6ce39;
  background-color: transparent;
}

.reviewEditIconn .squareIconBtnRed {
  background-color: #ef3d46;
  color: #fff;
}

.reviewEditIconn .squareIconBtnRed:hover {
  border-color: #ef3d46;
  background-color: transparent;
  color: #ef3d46;
}

.reviewEditIconn .squareIconBtnWhite {
  background-color: #fff;
  color: #0d4689;
}

.reviewEditIconn .squareIconBtnWhite:hover {
  background-color: #0d4689;
  color: #fff;
}

.uploadFilePreviewa {
  border: 1px solid #f0f0f0;
  border-radius: 0.5rem;
  background-color: #edf8f9;
  cursor: pointer;
  height: 76px;
  margin-right: 1rem;
  padding: 0.5rem;
  text-align: center;
  transition: all 0.3s;
  width: 84px;
}

.uploadFilePreviewGreenn {
  border-color: #a6ce39;
}

.uploadFilePreviewRedd {
  border-color: #ef3d46;
}

.uploadFilePreviewa:hover {
  background-color: #fff;
  box-shadow: 0 0 20px -8px rgba(0, 0, 0, 0.2);
}

.statementDetailBox {
  display: flex;
  background-color: #4fbbc8;
  border: 1px solid #4fbbc8;
  color: #fff;
  border-radius: 0.5rem;
}

.statementBox {
  background-color: #fff;
  border-radius: 0.5rem 0 0 0.5rem;
  display: flex;
  padding: 1rem;
  align-items: center;
  justify-content: center;
  min-width: 100px;
  color: #4fbbc8;
  cursor: pointer;
  font-size: 3.5rem;
}

.statementBox:hover {
  color: #0d4689;
}

.statementDetailBoxAdd {
  display: flex;
  background-color: #4fbbc8;
  border: 1px solid #4fbbc8;
  color: #fff;
  border-radius: 0.5rem;
}

.addStatementBox {
  background-color: #fff;
  font-size: 5.5rem;
  color: #4fbbc8;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  min-width: 100px;
  cursor: pointer;
}

.addStatementBox:hover {
  color: #fff;
  background-color: #0d4689;
  border-color: #0d4689;
}

.statementOuter {
  overflow: auto;
}

.statementOuter .statementDetailBox {
  min-width: 420px;
}

.statementOuter .statementDetailBoxSec {
  min-width: 500px;
}

.accordionOuter {
  border: unset;
  border-radius: unset;
  background-color: unset;
  margin-bottom: 1rem;
}

.accordionOuter:last-child {
  margin-bottom: 0;
}

.accordionOuter.accordionOuterExpanded {
  margin-top: 0;
}

.accordionOuter::before,
.accordionOuter::after {
  content: unset;
}

.accordionOuter .accordionHeader {
  background-color: #fff;
  border-radius: 0.5rem;
  border: 1px solid #4fbbc8;
  min-height: 3rem;
}

.accordionOuterExpanded .accordionHeader.accordionHeaderExpanded {
  min-height: 3rem;
  border-color: #0d4689;
  background-color: #0d4689;
  color: #fff;
}

.accordionOuterExpanded .accordionHeaderInner.accordionHeaderExpanded {
  margin: 0;
}

.accordionOuterExpanded .accordionIcon.accordionHeaderExpanded {
  color: #fff;
}

.accordionOuter .accordionDetailBox {
  padding: 1rem 0 0;
}

.pdfPreviewIcon {
  color: #fff;
}

.pdfPreviewIcon:hover {
  color: #0d4689;
}

.mapCard {
  background-image: url('../public/static/mapBg.png');
  background-repeat: no-repeat;
  background-position: center;
}

.locationIcon,
.locationIcon:hover {
  background-color: #0d4689;
  color: #fff;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  line-height: 50px;
}

.addBtn {
  padding: 0;
  background-color: #4fbbc8;
  color: #fff;
  display: inline-flex;
  border-radius: 8px;
  min-width: 100px;
  line-height: unset;
  text-decoration: unset;
  box-shadow: unset;
}

.addBtn:hover {
  background-color: #0d4689;
}

.addBtnSec {
  display: flex;
  flex-direction: column;
  padding: 0.75rem 1rem;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.addBtn label {
  padding: 0.75rem 1rem;
  height: 100%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.statusGreen {
  height: 22px;
  min-width: 22px;
  line-height: 0;
  border-radius: 8px;
  cursor: default;
  -webkit-box-align: center;
  align-items: center;
  white-space: nowrap;
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  padding: 0px 8px;
  color: rgb(34, 154, 22);
  font-size: 0.75rem;
  font-family: Poppins, sans-serif;
  background-color: rgba(84, 214, 44, 0.16);
  font-weight: 700;
}

.statusRed {
  height: 22px;
  min-width: 22px;
  line-height: 0;
  border-radius: 8px;
  cursor: default;
  -webkit-box-align: center;
  align-items: center;
  white-space: nowrap;
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  padding: 0px 8px;
  color: rgb(183, 33, 54);
  font-size: 0.75rem;
  font-family: Poppins, sans-serif;
  background-color: rgba(255, 72, 66, 0.16);
  font-weight: 700;
}

.statusYellow {
  height: 22px;
  min-width: 22px;
  line-height: 0;
  border-radius: 8px;
  cursor: default;
  -webkit-box-align: center;
  align-items: center;
  white-space: nowrap;
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  padding: 0px 8px;
  color: rgb(183, 129, 3);
  font-size: 0.75rem;
  font-family: Poppins, sans-serif;
  background-color: rgba(255, 193, 7, 0.16);
  font-weight: 700;
}

.linkBtn {
  width: 100%;
  justify-content: left;
  background-color: #eaedf1;
  border: 1px solid #4fbbc8;
}

.checkEditor {
  border-radius: 0.5rem;
}

.avtarHolderIcon {
  background-color: #4fbbc8;
  height: 36px;
  width: 36px;
  font-size: 1rem;
}

.avtarHolderIconSec {
  background-color: #4fbbc8;
  height: 28px;
  width: 28px;
  font-size: 0.875rem;
  margin-right: 0.5rem;
}

.arrowB {
  height: 55px;
  border: 2px solid #4fbbc8;
  border-top: unset;
  width: 60%;
  margin: 0 auto;
  position: relative;
}

.arrowB::before {
  width: 0;
  height: 0;
  border-width: 0 8px 10px 8px;
  position: absolute;
  top: 0;
  left: -8px;
  border-color: transparent transparent #4fbbc8 transparent;
  border-style: solid;
  content: '';
}

.arrowB::after {
  width: 0;
  height: 0;
  border-width: 0 8px 10px 8px;
  position: absolute;
  top: 0;
  right: -8px;
  border-color: transparent transparent #4fbbc8 transparent;
  border-style: solid;
  content: '';
}

.centerVLine {
  padding-top: 50px;
  position: relative;
}

.centerVLine::before {
  position: absolute;
  content: '';
  height: 50px;
  top: 0;
  left: 50%;
  translate: (0, -50%);
  width: 2px;
  background-color: #4fbbc8;
}

.signBox {
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  background-color: #edf8f9;
  margin-bottom: 0.75rem;
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  height: 80px;
}

.ckborderStyle .ck.ck-content {
  border: 1px solid red !important;
}

.approvalBox .signBox {
  padding: 0;
}

.sanctionedSign {
  background-color: #a6ce39;
  color: #fff;
  border-radius: 0.4rem;
  padding: 2px 8px;
}

.rejectedSign {
  background-color: #d60c17;
  color: #fff;
  border-radius: 0.4rem;
  padding: 2px 8px;
}

.pendingSign {
  background-color: #f36900;
  color: #fff;
  border-radius: 0.4rem;
  padding: 2px 8px;
}

.grid-item__graph {
  flex: 1;
}

body .react-grid-item > .react-resizable-handle.react-resizable-handle-se {
  right: -4px;
}

.draggable {
  cursor: move;
  user-select: none;
}

.avtarHolder {
  display: flex;
  justify-content: center;
}

.inputBoxEditor {
  border: 1px solid #787879;
  color: #787879;
  cursor: pointer;
  padding: 8.5px 10px;
  background-color: #fff;
  border-radius: 8px;
  font-weight: 500;
}

.ck.ck-content.ck-editor__editable {
  min-height: 100px;
}

.editorHolder .ck-editor__main .ck-content .table {
  display: block;
}

.descriptionContent figure.table tr:nth-of-type(odd) {
  background-color: rgba(145, 158, 171, 0.08);
}

.descriptionContent figure.table td,
.descriptionContent figure.table th {
  border: 1px solid #ccc;
  min-width: 1rem;
  padding: 0.25rem;
  font-size: 0.75rem;
  white-space: nowrap;
}

.descriptionContent figure.table table {
  border: 1px double #ccc;
  border-collapse: collapse;
  border-spacing: 0;
  height: 100%;
  width: auto;
  min-width: 400px;
  margin-bottom: 0.5rem;
  border-radius: 0.5rem;
}

.descriptionContent {
  /* padding-top: 9px; */
  font-size: 0.817rem;
  margin-bottom: 0.5rem;
  word-break: break-word;
}

.descriptionContent p {
  word-wrap: break-word;
  margin-bottom: 0.5rem;
  word-wrap: break-word;
}
.titleHolder {
  font-size: 0.875rem;
  padding: 8px 0;
  display: flex;
  align-items: center;
}

/* 
.allButtons {
    font-size: 18px;
}

.allButtons span {
    line-height: 0;
    cursor: pointer;
    padding: 3px;
    border: 1px solid #ddd;
    margin-right: 3px;
}

.allButtons span svg {
    color: #4FBBC8;
}

.allButtons span:hover svg {
    color: #787879;
} */

.grid-item {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
}

.headingLineUpOutRight {
  top: 0;
  right: 1.25rem;
  display: inline-block;
}

/* .react-grid-item.grid-item.react-draggable.cssTransforms.react-resizable {
    overflow-y: auto;
} */

.cusDataGrid {
  border: unset;
}

.cusDataGrid .cusCell {
  padding: 4px 10px;
  font-size: 11px;
}

.cusDataGrid .cusCell:focus {
  outline: none !important;
}

.cusDataGrid .cuscolumnHeader {
  color: #0d4689;
  text-transform: uppercase;
  font-weight: normal;
  font-size: 12px;
}

.cusDataGrid .cuscolumnHeadersInner {
  color: #0d4689;
  text-transform: uppercase;
  font-weight: normal;
  font-size: 12px;
}

.cusDataGrid .cusRows:nth-of-type(odd) {
  background-color: rgba(145, 158, 171, 0.08);
}

.cusiconButtonContainer,
.cusDataGrid .cusmenuIcon,
.cusDataGrid .cuscolumnSeparator {
  display: none;
}

.changeDataInfo {
  background-color: transparent;
  color: #ce0101;
  display: inline;
  margin-left: 0.5rem;
  padding: 0;
}

body .disabledBtn1 {
  color: #ce0101 !important;
  opacity: 0.4;
}

.cusGridMain > div:nth-child(3) {
  opacity: 0;
}

.dragTHead {
  padding: 0.4rem;
  font-size: 12px;
  text-align: left;
  border: 0 none;
  font-weight: 500;
  text-transform: uppercase;
  height: 36px;
}

.dragTdivider {
  border: 0 none;
  padding: 4px 6px;
  font-size: 11px;
  text-align: left;
  border-bottom: 1px solid rgba(241, 243, 244, 1);
  height: 36px;
}

.dragTRows.odd {
  background-color: rgba(145, 158, 171, 0.08);
}

.dragTable {
  border: unset;
  outline: unset;
  cursor: grab;
  width: 100%;
}

.mainHeader {
  width: 100%;
  background-position: bottom;
  /* background: linear-gradient(90deg,#27295f 0,#166bac 50%,#27295f); */
  padding-top: 6px;
  padding-bottom: 6px;
  border-bottom: 1px solid #f0f0f0;
  /* background: 'linear-gradient(to right, rgba(46,49,146,1), rgba(238,58,70,1))', */
  /* boxShadow: '0 0px 15px 2px rgb(0 0 0 / 10%)', */
  background-color: rgb(13, 70, 137);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.mainLogo {
  height: 40px;
}

.footer {
  background-color: #fff;
  border-top: 1px solid #eaedf1;
  bottom: 0;
  left: 0;
  padding: 8px 20px;
  position: fixed;
  right: 0;
  text-align: center;
  width: 100%;
  z-index: 999;
}

.uploadFilePreviewa {
  border: 1px solid #f0f0f0;
  border-radius: 0.5rem;
  background-color: #edf8f9;
  cursor: pointer;
  height: 76px;
  margin-right: 1rem;
  padding: 0.5rem;
  text-align: center;
  transition: all 0.3s;
  width: 84px;
}

.uploadFilePreviewGreenn {
  border-color: #a6ce39;
}

.uploadFilePreviewRedd {
  border-color: #ef3d46;
}

.uploadFilePreviewa:hover {
  background-color: #fff;
  box-shadow: 0 0 20px -8px rgba(0, 0, 0, 0.2);
}

.statementDetailBox {
  display: flex;
  background-color: #4fbbc8;
  border: 1px solid #4fbbc8;
  color: #fff;
  border-radius: 0.5rem;
}

.statementBox {
  background-color: #fff;
  border-radius: 0.5rem 0 0 0.5rem;
  display: flex;
  padding: 1rem;
  align-items: center;
  justify-content: center;
  min-width: 100px;
  color: #4fbbc8;
  cursor: pointer;
  font-size: 3.5rem;
}

.statementBox:hover {
  color: #0d4689;
}

.statementDetailBoxAdd {
  display: flex;
  background-color: #4fbbc8;
  border: 1px solid #4fbbc8;
  color: #fff;
  border-radius: 0.5rem;
}

.addStatementBox {
  background-color: #fff;
  font-size: 5.5rem;
  color: #4fbbc8;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  min-width: 100px;
  cursor: pointer;
}

.addStatementBox:hover {
  color: #fff;
  background-color: #0d4689;
  border-color: #0d4689;
}

.statementOuter {
  overflow: auto;
}

.statementOuter .statementDetailBox {
  min-width: 420px;
}

.statementOuter .statementDetailBoxSec {
  min-width: 500px;
}

.mapCard {
  background-image: url('../public/static/mapBg.png');
  background-repeat: no-repeat;
  background-position: center;
}

.lightBlueBgBtn {
  background-color: #94c6ff;
  border-color: #94c6ff;
}

.lightBlueBgBtn:hover {
  background-color: #4d97eb;
  border-color: #4d97eb;
}

.orangeBgBtn {
  background-color: #f36900;
  border-color: #f36900;
}

.orangeBgBtn:hover {
  background-color: #ca5902;
  border-color: #ca5902;
}

.verticleLine {
  height: 2.25rem;
  width: 0.1rem;
  background-color: #4fbbc8;
  margin: 0 auto;
}
.horizontalLine1 {
  height: 0.1rem;
  width: calc(100% - 141px);
  background-color: #4fbbc8;
  margin: auto;
}
.horizontalLine2 {
  height: 0.1rem;
  width: calc(100% - 170px);
  background-color: #4fbbc8;
  margin: auto;
}

.horizontalLineBottom {
  position: relative;
  height: 0.1rem;
  width: 84%;
  background-color: #4fbbc8;
  margin: auto;
}

.horizontalLineBottom::before {
  position: absolute;
  left: 0;
  top: 0.1rem;
  content: '';
  background-color: #4fbbc8;
  width: 0.1rem;
  height: 2.25rem;
}
.horizontalLineBottom::after {
  position: absolute;
  right: 0;
  top: 0.1rem;
  content: '';
  background-color: #4fbbc8;
  width: 0.1rem;
  height: 2.25rem;
}

.horizontalLineBottom.Htop::before {
  bottom: 0.1rem;
  top: unset;
}
.horizontalLineBottom.Htop::after {
  bottom: 0.1rem;
  top: unset;
}
.approverTitle {
  font-size: 11px;
}

.approverGroup {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 2.25rem;
  padding-bottom: 2.25rem;
  gap: 20px;
}

.approverSingle {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.smallFontSize {
  font-size: 0.6rem;
  line-height: 1.3;
}

.locationIcon,
.locationIcon:hover {
  background-color: #0d4689;
  color: #fff;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  line-height: 50px;
}

.addBtn {
  padding: 0;
  background-color: #4fbbc8;
  color: #fff;
  display: inline-flex;
  border-radius: 8px;
  min-width: 100px;
  line-height: unset;
  text-decoration: unset;
  box-shadow: unset;
}

.addBtn:hover {
  background-color: #0d4689;
}

.addBtnSec {
  display: flex;
  flex-direction: column;
  padding: 0.75rem 1rem;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.addBtn label {
  padding: 0.75rem 1rem;
  height: 100%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.statusGreen {
  height: 22px;
  min-width: 22px;
  line-height: 0;
  border-radius: 8px;
  cursor: default;
  -webkit-box-align: center;
  align-items: center;
  white-space: nowrap;
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  padding: 0px 8px;
  color: rgb(34, 154, 22);
  font-size: 0.75rem;
  font-family: Poppins, sans-serif;
  background-color: rgba(84, 214, 44, 0.16);
  font-weight: 700;
}

#nextButton:disabled {
  background-color: gray; /* Set the desired color for the disabled state */
  color: white; /* Set the text color for better visibility, if needed */
}

#updateBtn:disabled {
  background-color: gray; /* Set the desired color for the disabled state */
  color: white; /* Set the text color for better visibility, if needed */
}

.bureauBtn {
  background-color: #0d4689;
  border-radius: 8px;
  box-shadow: unset;
  color: #fff;
  display: inline-flex;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: unset;
  min-width: 100px;
  padding: 10px 20px;
  text-decoration: unset;
  margin-top: 10px;
  margin-left: 888px;
}

.statusRed {
  height: 22px;
  min-width: 22px;
  line-height: 0;
  border-radius: 8px;
  cursor: default;
  -webkit-box-align: center;
  align-items: center;
  white-space: nowrap;
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  padding: 0px 8px;
  color: rgb(183, 33, 54);
  font-size: 0.75rem;
  font-family: Poppins, sans-serif;
  background-color: rgba(255, 72, 66, 0.16);
  font-weight: 700;
}

.statusYellow {
  height: 22px;
  min-width: 22px;
  line-height: 0;
  border-radius: 8px;
  cursor: default;
  -webkit-box-align: center;
  align-items: center;
  white-space: nowrap;
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  padding: 0px 8px;
  color: rgb(183, 129, 3);
  font-size: 0.75rem;
  font-family: Poppins, sans-serif;
  background-color: rgba(255, 193, 7, 0.16);
  font-weight: 700;
}

.linkBtn {
  width: 100%;
  justify-content: left;
  background-color: #eaedf1;
  border: 1px solid #4fbbc8;
}

.checkEditor {
  border-radius: 0.5rem;
}

.avtarHolderIcon {
  background-color: #4fbbc8;
  height: 36px;
  width: 36px;
  font-size: 1rem;
}

.avtarHolderIconSec {
  background-color: #4fbbc8;
  height: 28px;
  width: 28px;
  font-size: 0.875rem;
  margin-right: 0.5rem;
}

.arrowB {
  height: 55px;
  border: 2px solid #4fbbc8;
  border-top: unset;
  width: 60%;
  margin: 0 auto;
  position: relative;
}

.arrowB::before {
  width: 0;
  height: 0;
  border-width: 0 8px 10px 8px;
  position: absolute;
  top: 0;
  left: -8px;
  border-color: transparent transparent #4fbbc8 transparent;
  border-style: solid;
  content: '';
}

.arrowB::after {
  width: 0;
  height: 0;
  border-width: 0 8px 10px 8px;
  position: absolute;
  top: 0;
  right: -8px;
  border-color: transparent transparent #4fbbc8 transparent;
  border-style: solid;
  content: '';
}

.centerVLine {
  padding-top: 50px;
  position: relative;
}

.centerVLine::before {
  position: absolute;
  content: '';
  height: 50px;
  top: 0;
  left: 50%;
  translate: (0, -50%);
  width: 2px;
  background-color: #4fbbc8;
}

.signBox {
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  background-color: #edf8f9;
  margin-bottom: 0.75rem;
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  height: 80px;
}

.sanctionedSign {
  background-color: #a6ce39;
  color: #fff;
  border-radius: 0.4rem;
  padding: 2px 8px;
}

.rejectedSign {
  background-color: #d60c17;
  color: #fff;
  border-radius: 0.4rem;
  padding: 2px 8px;
}

.pendingSign {
  background-color: #f36900;
  color: #fff;
  border-radius: 0.4rem;
  padding: 2px 8px;
}

.grid-item__graph {
  flex: 1;
}

body .react-grid-item > .react-resizable-handle.react-resizable-handle-se {
  right: -4px;
}

.draggable {
  cursor: move;
  user-select: none;
}

.avtarHolder {
  display: flex;
  justify-content: center;
}

.inputBoxEditor {
  border: 1px solid #787879;
  color: #787879;
  cursor: pointer;
  padding: 8.5px 10px;
  background-color: #fff;
  border-radius: 8px;
  font-weight: 500;
}

.ck.ck-content.ck-editor__editable {
  min-height: 100px;
}

.editorHolder .ck-editor__main .ck-content .table {
  display: block;
}

.descriptionContent figure.table tr:nth-of-type(odd) {
  background-color: rgba(145, 158, 171, 0.08);
}

.descriptionContent figure.table td,
.descriptionContent figure.table th {
  border: 1px solid #ccc;
  min-width: 1rem;
  padding: 0.25rem;
  font-size: 0.75rem;
  white-space: nowrap;
}

.descriptionContent figure.table table {
  border: 1px double #ccc;
  border-collapse: collapse;
  border-spacing: 0;
  height: 100%;
  width: auto;
  min-width: 400px;
  margin-bottom: 0.5rem;
  border-radius: 0.5rem;
}

.descriptionContent {
  /* padding-top: 9px; */
  font-size: 0.817rem;
  margin-bottom: 0.5rem;
  word-break: break-word;
}

.descriptionContent p {
  word-wrap: break-word;
  margin-bottom: 0.5rem;
}

.titleHolder {
  font-size: 0.875rem;
  padding: 8px 0;
  display: flex;
  align-items: center;
}

/* 
.allButtons {
    font-size: 18px;
}

.allButtons span {
    line-height: 0;
    cursor: pointer;
    padding: 3px;
    border: 1px solid #ddd;
    margin-right: 3px;
}

.allButtons span svg {
    color: #4FBBC8;
}

.allButtons span:hover svg {
    color: #787879;
} */

.grid-item {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
}

.headingLineUpOutRight {
  top: 0;
  right: 1.25rem;
  display: inline-block;
}

/* .react-grid-item.grid-item.react-draggable.cssTransforms.react-resizable {
    overflow-y: auto;
} */

body .loginSelectBox {
  border: unset;
  background-color: #fafafc;
  padding: 1rem 1rem;
  min-height: unset !important;
  font-size: 1rem;
  border-radius: 8px;
  background-color: #fff;
}

body .loginSelectBox:focus {
  background-color: #f0f8f7;
  border-color: #4fbbc8;
}

.approvalButton {
  color: #fff !important;
}

.approvalButton {
  color: #fff !important;
}

.approvalButtonDisabled {
  background-color: #ddd;
}

.disabled {
  background-color: #ddd;
  color: #ddd;
  border-color: #ddd;
}
.disabledLink {
  color: currentColor;
  cursor: not-allowed;
  opacity: 0.5;
  text-decoration: none;
  pointer-events: none;
}

.disabledBttn {
  border-radius: 3px;
  border: 1px solid #ddd;
  padding: 6px;
  color: #ddd;
  background-color: #ddd;
}

.uploadSignBtn {
  font-size: 12px;
  padding: 0.5rem 1rem;
  background: #dae0f5;
  color: #0d4689;
  cursor: pointer;
  letter-spacing: 0.5px;
  font-weight: 500;
  border-radius: 8px;
  display: inline-flex;
  min-width: 100px;
  line-height: unset;
  text-decoration: unset;
  box-shadow: unset;
}

.uploadSignBtn:hover,
.uploadSignBtn:focus {
  background-color: #0d4689;
  color: #fff;
}

.uploadSignB {
  display: flex;
  align-items: center;
}

.commentNumber {
  font-size: 12px;
  padding: 0.25rem 0.75rem;
  background: #dae0f5;
  color: #0d4689;
  border-radius: 0.5rem;
  margin-left: 1rem;
  margin-bottom: 0.5rem;
  display: inline-block;
}

.greenbg {
  background-color: rgba(84, 214, 44, 0.16) !important;
  border-right: 1px solid #166bac !important;
}

.redbg {
  background-color: rgba(255, 72, 66, 0.16) !important;
  border-right: 1px solid #0d4689 !important;
}

.StaticCardBox {
  display: flex;
  align-items: center;
  justify-content: center;
}

.customdatePicker {
  width: 100%;
  border: 1px solid rgba(145, 158, 171, 0.32);
  padding: 10px;
}

.customdatePicker:focus {
  border-color: #0d4689;
}

.customiseddatePicker {
  z-index: 999;
  width: 100%;
  border: 1px solid rgba(145, 158, 171, 0.32);
  padding: 5px 9px;
}

.customiseddatePicker:focus {
  border-color: #0d4689;
}

.borderGreenColor {
  border-color: #00ad09;
}

.borderRedColor {
  border-color: #c71616;
}

.greenBackground {
  background-color: #00ad09;
}

.redBackground {
  background-color: #c71616;
}

.outer-box {
  width: 100%;
  margin: 0 auto;
}

.header-outer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.header-logo {
  text-align: center;
  display: flex;
  justify-content: center;
}

.header-logo img {
  height: 60px;
}

.print-heading {
  background-color: #000;
  color: #fff;
  padding: 0.2rem 0.5rem;
  margin-bottom: 0.5rem;
}

.table-outer-box {
  margin-bottom: 1rem;
  line-height: 30px;
}

.print-table {
  width: 100%;
  border-left: 1px solid #000;
  border-top: 1px solid #000;
}

.print-table th,
.print-table td {
  border-bottom: 1px solid #000;
  border-right: 1px solid #000;
  text-align: left;
  padding: 2px 5px;
  font-size: 13px;
}

.comments-outer {
  margin-bottom: 1rem;
  padding-left: 1rem;
}

.comments-heading {
  margin-bottom: 0.25rem;
  font-size: 13px;
  margin-top: 0.5rem;
}

.comments-description {
  font-size: 13px;
}

.value-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
}

.react-pdf__message--error,
.react-pdf__message--loading {
  display: none !important;
}

.react-datepicker-wrapper {
  border: 0;
  display: inline-block;
  padding: 0;
  width: 100%;
}

.customTextField {
  /* Helper text color customization */
  & .MuiFormHelperText-root {
    color: #c8c8c8; /* Change color as needed */
    font-size: 10px;
  }
}
/* Helper text font size customization */

/* #recommnadationDownloadPrint {
  display: none;
} */

/* Admin */
.linkBtn1 {
  text-decoration: unset;
  font-size: 1.1rem;
  color: #0d4689;
}
.linkBtn1:hover {
  color: #4fbbc8;
}

.changeBgcolor {
  background-color: #1494a2;
}
.blueBGCard {
  background-color: #4087d8;
  color: #fff;
}
.changeBgcolor.blueBGCard {
  background-color: #1b5fac;
}
.grayBGCard {
  background-color: #0d478951;
  color: #fff;
}
.changeBgcolor.grayBGCard {
  background-color: #0d478979;
}
.logoB {
  text-align: center;
  display: flex;
  justify-content: center;
}
.logoB img {
  height: 60px;
}

.printHeading {
  background-color: #000;
  color: #fff;
  padding: 0.2rem 0.5rem;
}

/* new css */
.redBorder {
  border: 1px solid #ce0101;
}

.backgroundGreenLight {
  background-color: #dfeef0 !important;
}

.linearProgreeBarCustom {
  height: 0.75rem;
  border-radius: 0.5rem;
}
.linearProgressBoxSec {
  align-items: center;
  background-color: #4fbbc8;
  border-radius: 0.5rem;
  color: #fff;
  display: flex;
  padding: 0.5rem 1rem;
}
.linearProgressBoxSec .linearProgreeBarCustom {
  background-color: #fff;
}
.linearPBSelected {
  background-color: #0d4689;
}
.timeLineHeading,
.timelineYear,
.timeLineText {
  font-size: 11px;
}
.timelineDot {
  margin: 0.5rem 0;
  background-color: #edf8f9;
}
.timelineDotApproved {
  margin: 0.5rem 0;
  background-color: #00008b;
}
.viewProcessingCard {
  border-radius: 0.5rem;
  background-color: #4fbbc8;
  padding: 0.5rem 1rem;
  color: #fff;
}
.timelineConnector {
  background-color: #edf8f9;
}
.timelineConnectorApproved {
  background-color: #00008b;
}
.timelineYear {
  color: #edf8f9;
}

.tabDefaultSmall {
  min-width: 100px;
}

.infoIcon {
  color: #ce0101;
  position: relative;
  right: 0;
}
.infoIconApproved {
  color: #00ad09;
  position: relative;
  right: 0;
}
.grayBox {
  background-color: #f3f5f7;
  border-radius: 0.5rem;
  padding: 1rem;
}
.lightBlueBox {
  background-color: #e8f4f6;
  border-radius: 0.5rem;
  padding: 1rem;
}
/* .bankRecoBox {
    align-items: center;
    background-image: url(https://klass-ui.kalolytic.com/static/media/congrats-img2.ea7b3626aaf96a375753.webp);
    background-position: top -75px center;
    background-repeat: no-repeat;
    background-size: 100% auto;
    border-radius: .5rem;
    display: flex;
    flex-direction: column;
    height: 200px;
    justify-content: center;
    position: relative;
} */

.tableBtnSmall {
  font-size: 0.75rem;
  padding: 0 0.5rem;
  background: #dae0f5;
  color: #0d4689;
  height: 22px;
  min-width: 60px;
}

/* New style for Flow */
.cursorPointer {
  cursor: pointer;
}
.flowInnerB {
  width: 550px;
  margin: 0 auto;
}
.inputTypeB {
  padding: 0 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.inputIcon {
  background-color: #9f6cd9;
  padding: 0.75rem;
  border-radius: 2rem;
  color: #fff;
  display: flex;
}
.inputIcon1 {
  background-color: #0078d7;
}
.inputIcon2 {
  background-color: #01b8aa;
}
.inputIcon3 {
  background-color: #107c10;
}
.inputIcon4 {
  background-color: #b4009e;
}
.inputIcon3 {
  background-color: #4f6bed;
}
.inputIconSmall {
  padding: 0.5rem;
}
.textFieldBox {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #ddd;
}
.textFieldType {
  border: 1px solid rgba(145, 158, 171, 0.32);
  padding: 0.3rem 10px;
  background-color: #fff;
  border-radius: 0.5rem;
  font-weight: 300;
  width: 100px;
  background-color: #eaedf1;
  font-size: 0.75rem;
}

.firstIconAcc {
  background-color: #4fbbc8;
  color: #fff;
  padding: 8px;
  display: flex;
  align-items: center;
  margin-right: 0.75rem;
  border-radius: 0.2rem 0 0 0.2rem;
}
.secIconAcc {
  background-color: rgb(13, 70, 137);
}
.thirdIconAcc {
  background-color: rgb(72, 79, 88);
}
.fourthIconAcc {
  background-color: rgb(136, 218, 141);
}
.fiveIconAcc {
  background-color: rgb(251 137 129);
}
.sixIconAcc {
  background-color: rgb(140 108 255);
}
.sevenIconAcc {
  background-color: rgb(140 108 255);
}
.moreMenuAcc {
  padding: 0.4rem;
  margin-right: 0.5rem;
}
.nextStepArrowDown {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.addNewStepB {
  top: 0.3rem;
  display: none;
  transition: all 0.3s;
  position: absolute;
}
.addStep {
  background: #fff;
  padding: 0.25rem;
  color: #01b8aa;
  border: 1px solid #ddd;
}
.addStep:hover,
.addStep:focus {
  background: #fff;
  padding: 0.25rem;
  color: #01b8aa;
  border-color: #01b8aa;
}
.nextStepArrowDown:hover .addNewStepB {
  display: block;
}

/* accordion second */
.accordionOuterSec {
  border: 1px solid #ddd !important;
  border-radius: 0.25rem; /* margin-bottom: 1rem; */
  transition: all 0.3s;
}
.accordionOuterSec.accordionOuterExpandedSec {
  border-color: #1494a2;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}
.accordionOuterSecMB0 {
  margin-bottom: 0;
}
.accordionOuterSec:last-child {
  margin-bottom: 0;
}
.accordionOuterSec.accordionOuterExpanded {
  margin-top: 0;
}
.accordionOuterSec.accordionOuterExpandedMB0 {
  margin-bottom: 0;
}
.accordionOuterSec::before,
.accordionOuterSec::after {
  content: unset;
}
.accordionOuterSecExpanded .accordionHeaderSec.accordionHeaderExpanded {
  min-height: 3rem;
  border-color: #0d4689;
  background-color: #0d4689;
  color: #fff;
}
.accordionOuterSec .accordionHeaderSec .accordionIcon {
  display: none;
}
.accordionOuterSecExpanded .accordionHeaderInner.accordionHeaderExpanded {
  margin: 0;
}
.accordionOuterSecExpanded .accordionIcon.accordionHeaderExpanded {
  color: #fff;
}
.accordionOuterSec .accordionDetailBox {
  padding: 1rem;
  border-top: 1px solid #edf8f9;
}
.accordionOuterSec .accordionDetailBox.accordionDetailBoxSmall {
  padding: 0.5rem 0 0;
}
.accordionOuterSec .accordionHeaderSec {
  background-color: #edf8f9;
  border-radius: 0.25rem;
  min-height: unset;
  padding: 0;
}
.accordionOuterSecExpanded .accordionHeaderSec.accordionHeaderExpanded {
  min-height: 2rem;
  background-color: #0d4689;
  color: #fff;
}
.accordionOuterSec .accordionHeaderSmall.accordionHeaderSec.accordionHeaderExpanded {
  min-height: 2rem;
}
.accordionHeaderInnerSmall {
  margin: 6px 0 6px 6px;
}
.accordionOuterSecExpanded .accordionHeaderInnerSmall.accordionHeaderExpanded {
  margin: 6px 0 6px 6px;
}

.accordionOuterSec .accordionHeaderSec .accordionHeaderInner {
  margin: 0;
}

.accordionOuterSec .accordionHeaderSec.accBlueBg {
  background-color: rgba(13, 70, 137, 0.15);
}

.accordionOuterSec .accordionHeaderSec.accGrayBg {
  background-color: rgba(72, 79, 88, 0.15);
}
.accordionOuterSec .accordionHeaderSec.accBlueBg1 {
  background-color: rgba(140, 108, 255, 0.15);
}

body .paddinfLeft {
  padding-left: 1.75rem;
}
.operationIconB {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  padding: 0.4rem 0.7rem;
  border-radius: 0.25rem;
  width: 70px;
}
.operationIconB:hover {
  background-color: #f7f8f9;
}
.OperationIcon {
  border-radius: 0.25rem;
  background-color: #0078d7;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  margin-bottom: 0.5rem;
  width: 48px;
  height: 48px;
}
.OperationText {
  font-size: 0.7rem;
  line-height: 14px;
}
.OIColor1 {
  background-color: rgb(10, 118, 196);
}
.OIColor2 {
  background-color: #464f59;
}
.OIColor3 {
  background-color: #0066ff;
}
.OIColor4 {
  background-color: #107c41;
}
.OIColor5 {
  background-color: #0078d7;
}
.OIColor6 {
  background-color: #008299;
}
.OIColor7 {
  background-color: #4853bc;
}

.ExpandBtnBox {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.ExpandBtn {
  width: 100%;
  background-color: #f7f8f9;
  border: 1px solid #e0e0e0;
  padding: 0.1rem 0.5rem;
}
.TriggerActionB {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0.4rem 0.7rem;
  border-radius: 0.25rem;
}
.TriggerActionB:hover {
  background-color: #f7f8f9;
}
.TriggerActionB .OperationIcon {
  margin-bottom: 0;
  margin-right: 0.5rem;
}

.flowParrallelBox {
  display: flex;
  justify-content: center;
  padding: 0 0.75rem;
  position: relative;
}
.flowParrallelBox:first-child::after {
  position: absolute;
  content: ' ';
  top: 0;
  right: 0;
  background-color: #515151;
  width: 50%;
  height: 0.15rem;
}
.flowParrallelBox:last-child::before {
  position: absolute;
  content: ' ';
  top: 0;
  left: 0;
  background-color: #515151;
  width: 50%;
  height: 0.15rem;
}
.flowConditionBox {
  display: flex;
  padding: 0 0.5rem;
}
.conditionBox {
  background-color: #fff;
  border-radius: 0.25rem;
}
.conditionBox .conditionHeader {
  background-color: rgba(72, 79, 88, 0.15);
  cursor: pointer;
  border-radius: 0.25rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.conditionBox .conHeaderGreen {
  background-color: rgba(136, 218, 141, 0.15);
}
.conditionBox .conHeaderRed {
  background-color: rgba(251, 137, 129, 0.15);
}

.mandatoryApprover {
  position: absolute;
  top: -10px;
  left: -10px;
  background-color: #fff;
  border-radius: 14px;
  height: 1.25rem;
  width: 1.25rem;
  color: #ce0101;
  text-align: center;
  line-height: 1.6rem;
}

.customDisabledButton:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  color: whitesmoke;
  text-decoration-color: whitesmoke;
}

.dateParent .react-datepicker-popper {
  z-index: 100;
}
.bureauBox {
  border-radius: 0.5rem;
  padding: 0.5rem 0.75rem;
  margin-bottom: 1rem;
  top: 0px;
  position: relative;
  background-color: #fff;
  cursor: pointer;
  transition: all 0.2s ease-out;
  width: 200px;
  /* height: 100px; */
  border: 1px solid #4fbbc8;
}

.bureauBox.selected {
  background-color: #0d4689;
  color: #fff;
}

.lightBlueBgBtn {
  background-color: #94c6ff;
  border-color: #94c6ff;
}

.lightBlueBgBtn:hover {
  background-color: #4d97eb;
  border-color: #4d97eb;
}

.orangeBgBtn {
  background-color: #f36900;
  border-color: #f36900;
}

.orangeBgBtn:hover {
  background-color: #ca5902;
  border-color: #ca5902;
}

.whiteBG {
  background-color: #fff;
  color: #000;
  border-radius: 0.25rem;
  padding: 0.1rem 0.5rem;
}

.blueBGCardSec {
  padding: 0.5rem 1rem;
  color: #fff;
  border-radius: 0.5rem;
  background-color: #601da7;
}
.orangeBGCardSec {
  padding: 0.5rem 1rem;
  color: #fff;
  border-radius: 0.5rem;
  background-color: #ec7a00;
}
.normalBGCardSec {
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
}
.orangeBGCardSmall,
.blueBGCardSmall {
  padding: 2px 6px;
  border-radius: 0.25rem;
}
.greenBGCardSec {
  padding: 0.5rem 1rem;
  color: #fff;
  border-radius: 0.5rem;
  background-color: #00c957;
}

.blueBox {
  background-color: #0d4689;
  color: #fff;
  border-radius: 0.5rem;
  padding: 0.5rem;
}
.orangeBox {
  /* background-color: #ec7a00; */
  color: #fff;
  border-radius: 0.5rem;
  padding: 0.5rem;
}
.blueBoxSmall,
.orangeBoxSmall {
  width: 82px;
}

.removeIcon,
.removeIcon:hover,
.removeIcon:focus {
  padding: 0.2rem;
  background-color: #4fbbc8;
  color: #fff;
  border: 1px solid #4fbbc8;
  border-radius: 0.5rem;
}
.headingForm {
  /* background: #4FBBC8; */
  /* color: #fff; */
  padding: 0.3rem 2rem;
  border-radius: 0.5rem;
  display: inline-block;
  position: relative;
  font-weight: bold;
  background-color: #f0f8f7;
  border: 1px solid #4fbbc8;
}
.containerwatermark {
  position: relative;
  width: 100%; /* Set the width of your container */
  height: 100%; /* Set the height of your container */
}

.containerwatermark::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('samplewater.jpg');
  opacity: 0.5;
  pointer-events: none;
  background-repeat: no-repeat;
  background-size: contain;
}

.bulletPoint {
  position: relative;
  padding-left: 1rem;
}
.bulletPoint::before {
  position: absolute;
  left: 0;
  top: 8px;
  content: '';
  width: 6px;
  height: 6px;
  border-radius: 3px;
  background-color: #000;
}

.securityBox {
  background-color: #fff;
  border: 1px solid #4fbbc8;
  border-radius: 0.5rem;
  cursor: pointer;
  margin-bottom: 1rem;
  padding: 0.5rem 0.75rem;
  position: relative;
  top: 0;
  transition: all 0.2s ease-out;
  min-width: 200px;
}
.securityBox.selected {
  background-color: #0d4689;
  color: #fff;
}

.customCheckBox.bgNotDisable.Mui-checked {
  color: #0d4689 !important;
}

.regBtn {
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-color: red;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  outline: none;
  animation: blinkAnimation 1s linear infinite;
}
.saveBtn {
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-color: #0d4689;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  outline: none;
  animation: blinkAnimation1 1s linear infinite;
}

@keyframes blinkAnimation {
  0%,
  100% {
    background-color: red;
    color: white;
  }
  50% {
    background-color: white;
    color: red;
  }
}
@keyframes blinkAnimation1 {
  0%,
  100% {
    background-color: #0d4689;
    color: white;
  }
  50% {
    background-color: white;
    color: #0d4689;
  }
}

body .ck-content ul,
body .ck-content ol {
  padding-left: 18px !important;
}

.dynamicCardIndex .react-datepicker-popper {
  z-index: 99;
}

.newReportLinkB {
  background-color: #eaedf1;
  border: 1px solid #eaedf1;
  border-radius: 4px;
  cursor: pointer;
  padding: 1.5rem 1rem;
  transition: all 0.3s;
}

.newReportLinkB:hover {
  background-color: #fff;
  box-shadow: 0 0 20px -8px rgba(0, 0, 0, 0.2);
  color: #0d4689;
}

.newReportIcon {
  font-size: 2rem;
  margin-bottom: 0.5rem;
}
